import { IconButton, Tooltip } from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import { useClipboard } from '@chakra-ui/react';

export default function ClipboardIcon(props: { value: any }) {
  const { value } = props;
  const { hasCopied, onCopy } = useClipboard(value);

  return (
    <Tooltip 
      label={"Copied!"} 
      isOpen={hasCopied}                                 
      hasArrow
      bg="#6b52ff"                                 
      color="white"                               
      placement="top"                                   
    >
      <IconButton
        aria-label="Copy name"
        icon={<CopyIcon />}
        onClick={onCopy}
        bg="transparent"
        _hover={{
          bg: "transparent",
          transform: "scale(1.2)"
        }}
        color="current"
        transition="transform 0.2s, color 0.2s"
      />
    </Tooltip>
  );
}

  
  
  
