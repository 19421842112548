import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Switch,
  useToast,
  Text,
  Tag,
  useClipboard,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useUpdateCreative } from 'api/staff-api';
import { useParams } from 'react-router-dom';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { AsyncSelect } from 'chakra-react-select';
import { useLoadCityOptionsWithDebounce } from '../helpers/getCities';
import { LuCopy } from 'react-icons/lu';
import { LuCopyCheck } from 'react-icons/lu';
import { useFormContext } from 'contexts/useFormContext';

export default function CreativeBasicData(props: { creativeData: any }) {
  const { setIsFormDirty, shouldReset, setShouldReset } = useFormContext();
  const { creativeData } = props;
  const { creativeId } = useParams();
  const toast = useToast();
  const [workingArea, setWorkingArea] = useState(creativeData.workingArea);
  const loadCityOptionsWithDebounce = useLoadCityOptionsWithDebounce();
  const { onCopy, hasCopied } = useClipboard(creativeData._id);

  const defaultValues = {
    _id: creativeData._id,
    name: creativeData.name,
    email: creativeData.email,
    phone: creativeData.phone,
    portfolioUrl: creativeData.portfolioUrl,
    facebookUrl: creativeData.facebookUrl,
    instagramUrl: creativeData.instagramUrl,
    heardAboutUs: creativeData.heardAboutUs,
    acceptsTermsOfUse: creativeData.acceptsTermsOfUse,
    acceptsPrivacyPolicy: creativeData.acceptsPrivacyPolicy,
    workingArea: creativeData.workingArea,
    specificCityNotify: !creativeData.specificCityNotify,
    homeTown: creativeData.homeTown,
  };

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, isDirty },
    setValue,
    watch,
    reset,
  } = useForm({
    defaultValues,
  });

  const [{ error: updateCreativeError }, updateCreative] = useUpdateCreative(creativeId);

  const handleSaveClick = async (data: any) => {
    const extendData = { ...data, workingArea, specificCityNotify: !data.specificCityNotify };

    try {
      await updateCreative({
        data:  extendData ,
      });
      toast({
        title: 'Creative updated',
        status: 'success',
        duration: 3000,
      });
    } catch {
      toast({
        title: 'Error during update',
        status: 'error',
        duration: 3000,
      });
    }
    setIsFormDirty(false);
  };

  const watchHomeTown = watch('homeTown');

  const handleSelectWorkingArea = (input: { value: string }) => {
    const city = input.value;

    const updatedWorkingArea = workingArea?.length ? [...workingArea] : [];
    const cityIndex = updatedWorkingArea.indexOf(city);

    if (cityIndex !== -1) {
      updatedWorkingArea.splice(cityIndex, 1);
    } else {
      updatedWorkingArea.push(city);
    }

    setWorkingArea(updatedWorkingArea);
  };

  const removeCityFromWorkingArea = (city: string) => {
    const updatedWorkingArea = workingArea.filter((item: string) => item.trim() !== city.trim());
    setWorkingArea(updatedWorkingArea);
  };

  useEffect(() => {
    if (isDirty) {
      setShouldReset(false);
      setIsFormDirty(isDirty);
    }
  }, [isDirty]);

  useEffect(() => {
    if (shouldReset) reset(defaultValues);
  }, [shouldReset]);

  return (
    <Box mt='50px'>
      <form onSubmit={handleSubmit(handleSaveClick)}>
        <Flex justifyContent='space-around' direction={{ base: 'column', md: 'row' }}>
          <Flex direction='column' alignItems={'center'} justifyContent={'center'}>
            <FormControl id='_id' mb={4}>
              <FormLabel>Id</FormLabel>
              <Flex w={{ base: '90vw', md: '500px' }}>
                <Input variant='main' disabled {...register('_id')} />
                <Button ml='5px' onClick={onCopy}>
                  {hasCopied ? <LuCopyCheck size={25} /> : <LuCopy size={25} />}
                </Button>
              </Flex>
            </FormControl>
            <FormControl id='name' mb={4}>
              <FormLabel>Name</FormLabel>
              <Input variant='main' {...register('name')} />
            </FormControl>
            <FormControl id='email' mb={4}>
              <FormLabel>Email</FormLabel>
              <Input variant='main' {...register('email')} />
            </FormControl>
            <FormControl id='phone' mb={4}>
              <FormLabel>Phone</FormLabel>
              <Input variant='main' {...register('phone')} />
            </FormControl>
            <FormLabel w='100%' ml='10px'>
              Accepts work at
            </FormLabel>
            <Flex flexWrap='wrap' w={{ base: '90vw', md: '500px' }} mb='10px'>
              {workingArea?.map((city: string) => (
                <Tag fontSize='15px' key={city} mx={{ lg: '1px' }} m='5px'>
                  {city}
                  <Button
                    h='20px'
                    w='10px'
                    borderRadius='50px'
                    ml='5px'
                    minW='10px'
                    onClick={() => removeCityFromWorkingArea(city)}
                  >
                    <CloseIcon fontSize={'8px'} />
                  </Button>
                </Tag>
              ))}
            </Flex>
            <AsyncSelect
              name='workingArea'
              placeholder='Select working area'
              loadOptions={loadCityOptionsWithDebounce}
              onChange={handleSelectWorkingArea}
              chakraStyles={{
                container: (provided: any) => ({
                  ...provided,
                  width: { base: '90vw', md: '500px' },
                  bg: 'white',
                }),
                placeholder: (provided: any) => ({
                  ...provided,
                  px: '14px',
                  color: 'black',
                }),
              }}
            />
            <FormControl
              display='flex'
              alignItems='center'
              id='specificCityNotify'
              m='10px 0'
              w={{ base: '90vw', md: '500px' }}
            >
              <FormLabel>Want to hear about projects in other cities</FormLabel>
              <Switch
                {...register('specificCityNotify')}
                defaultChecked={!creativeData.specificCityNotify}
                mb='10px'
              />
            </FormControl>
          </Flex>

          <Flex direction='column'>
            <FormControl id='portfolioUrl' mb={4}>
              <FormLabel>Portfolio</FormLabel>
              <Input variant='main' {...register('portfolioUrl')} />
            </FormControl>

            <FormControl id='facebookUrl' mb={4}>
              <FormLabel>Facebook</FormLabel>
              <Input variant='main' {...register('facebookUrl')} />
            </FormControl>

            <FormControl id='instagramUrl' mb={4}>
              <FormLabel>Instagram</FormLabel>
              <Input variant='main' {...register('instagramUrl')} />
            </FormControl>

            <FormControl id='heardAboutUs' mb={4}>
              <FormLabel>Heard about us</FormLabel>
              <Input variant='main' {...register('heardAboutUs')} />
            </FormControl>

            <FormControl id='homeTown' mb={4}>
              <FormLabel>Hometown</FormLabel>
              <AsyncSelect
                name='homeTown'
                placeholder='Select hometown'
                loadOptions={loadCityOptionsWithDebounce}
                onChange={(option: any) => setValue('homeTown', option.value)}
                value={
                  watchHomeTown
                    ? { value: watchHomeTown, label: watchHomeTown }
                    : { value: '', label: 'Select area' }
                }
                chakraStyles={{
                  container: (provided: any) => ({
                    ...provided,
                    width: { base: '90vw', md: '500px' },
                    bg: 'white',
                  }),
                  placeholder: (provided: any) => ({
                    ...provided,
                    px: '14px',
                    color: 'black',
                  }),
                }}
              />
            </FormControl>

            <FormControl display='flex' alignItems='center' id='acceptsPrivacyPolicy' m='5px 0'>
              <FormLabel>Accepts privacy policy</FormLabel>
              <Text mb='10px'>
                {creativeData.acceptsPrivacyPolicy ? <CheckIcon /> : <CloseIcon />}
              </Text>
            </FormControl>

            <FormControl display='flex' alignItems='center' id='acceptsTermsOfUse' m='5px 0'>
              <FormLabel>Accepts terms of use</FormLabel>
              <Text mb='10px'>
                {creativeData.acceptsTermsOfUse ? <CheckIcon /> : <CloseIcon />}
              </Text>
            </FormControl>
          </Flex>
        </Flex>
        <Flex justifyContent='center' m='50px'>
          <Button isLoading={isSubmitting} type='submit' variant='photonDark' w='200px'>
            Save
          </Button>
        </Flex>
      </form>
    </Box>
  );
}
