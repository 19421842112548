import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';

import { useState } from 'react';
import EditorCoc from './components/EditorCoc';

export default function EditorFinances() {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Text fontSize='25px' fontWeight='bold' m='20px'>
        Editor finances
      </Text>
      <Tabs index={selectedTab} onChange={handleTabChange}>
        <TabList ml='15px'>
          <Tab>COC</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <EditorCoc />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
