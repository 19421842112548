import { InputRightElement, Button } from "@chakra-ui/react";
import { TiDeleteOutline } from "react-icons/ti";

export const RemoveInputContent = ({
    handleFilterChange,
    name,
  }: {
    handleFilterChange: Function;
    name: string;
  }) => {
    return (
      <InputRightElement>
        <Button size='20' onClick={() => handleFilterChange('', name)}>
          <TiDeleteOutline size='20' />
        </Button>
      </InputRightElement>
    );
  };