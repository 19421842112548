import React from 'react';
import MenuLayout from '../../../layouts/MenuLayout';
import RoleGuard from '../../../layouts/RoleGuard';
import { adminRoles } from '../../../variables/roles';
import EditorFinances from './EditorFinances';

export default function EditorFinancesPage() {
  return (
    <RoleGuard page roles={[adminRoles.RolePhotonAdmin.key, adminRoles.RolePhotonSuperAdmin.key]}>
      <MenuLayout page={<EditorFinances />} />
    </RoleGuard>
  );
}
