import {
  Box,
  Button,
  Flex,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  useGetCreativeFeeDocs,
  useGetAllCreativeFeeDocs,
  useGetAllCreativeCertCSV,
  useGetAllCreativeFeeDocsCsv,
} from 'api/staff-api';
import moment from 'moment';

import { useCallback, useEffect, useState } from 'react';
import BillingPeriodSelector from 'views/dashboard/components/BillingPeriodSelector';
import YearSelector from 'views/dashboard/components/YearSelector';
import { DownloadIcon } from '@chakra-ui/icons';
import { currencyFormatter } from 'utils/number-formatter';
import { downloadFile } from 'utils/download-file';
import Card from 'components/card/Card';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetAllClientInvoiceZip, useGetClientInvoices } from 'api/clients';
import useCountryList from 'hooks/useCountryList';

export default function Invoices() {
  const location = useLocation();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const [year, setYear] = useState(urlParams.get('year') || moment().format('YYYY'));
  const [period, setPeriod] = useState(urlParams.get('period') || '');
  const {getCountryName} = useCountryList();


  const [{ loading }, getAllClientInvoiceZip] = useGetAllClientInvoiceZip();
  const [{ data: clientInvoiceData }, getClientInvoices] = useGetClientInvoices();

  const callGetClientInvoices = useCallback(async (year: string, period: string) => {
    if (year && period) {
      try {
        await getClientInvoices({
          params: {
            year,
            period,
          },
        });
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  const callGetAllClientInvoiceZip = useCallback(async (year: string, period: string) => {
    let result;
    if (year && period) {
      try {
        result = await getAllClientInvoiceZip({
          params: {
            year,
            period,
          },
          responseType: 'arraybuffer',
        });
      } catch (e) {
        console.log(e);
      } finally {
        if (result && result.data) {
          downloadFile(result.data, `client_invoices${year}_${period}.zip`, 'application/zip');
        }
      }
    }
  }, []);

  useEffect(
    // @ts-ignore
    () => {
      callGetClientInvoices(year, period);
      return callGetClientInvoices;
    },
    [year, period],
  );

  useEffect(() => {
    const updatedUrl = `${location.pathname}?year=${year}&period=${period}`;
    navigate(updatedUrl);
  }, [year, period]);

  const isButtonDisabled =
    !year || !period || !clientInvoiceData || clientInvoiceData?.length === 0;

  return (
    <Card>
      <Text fontSize='18px' fontWeight='bold' m='10px'>
        Invoices
      </Text>
      <Flex justifyContent='space-between'>
        <Flex>
          <YearSelector year={year} setYear={setYear} />
          <BillingPeriodSelector year={year} billingPeriod={period} setBillingPeriod={setPeriod} />
        </Flex>
      </Flex>
      <Flex justifyContent='center'>
        {clientInvoiceData?.length > 0 ? (
          <TableContainer w='100%' mt='50px'>
            <Table variant='simple'>
              <Thead>
                <Tr borderBottom={`solid #E2E8F0`}>
                  <Th>Client</Th>
                  <Th textAlign='center'>Country</Th>
                  <Th textAlign='center'>Billing period</Th>
                  <Th textAlign='center'>Invoice number</Th>
                  <Th textAlign='center'>HUF</Th>
                  <Th textAlign='center'>EUR</Th>
                </Tr>
              </Thead>
              <Tbody>
                {clientInvoiceData.map((row: any, i: string) => (
                  <Tr key={i}>
                    <Td>{row.client}</Td>
                    <Td textAlign='center'>{getCountryName(row.country)}</Td>
                    <Td textAlign='center'>{row.date}</Td>
                    <Td textAlign='center'>
                      {row.certs.HUF?.serialNumber || row.certs.EUR?.serialNumber}
                    </Td>
                    <Td>
                      {row?.certs?.HUF && (
                        <Flex justifyContent='center'>
                          <Text mr='10px'>
                            {row.certs.HUF.netSum &&
                              `${currencyFormatter(row.certs.HUF.netSum, 'HUF')} HUF`}
                          </Text>
                          <Link isExternal target='_blank' href={row.certs.HUF.url}>
                            {<DownloadIcon />}
                          </Link>
                        </Flex>
                      )}
                    </Td>
                    <Td>
                      {row?.certs?.EUR && (
                        <Flex justifyContent='center'>
                          <Text mr='10px'>
                            {row.certs.EUR.netSum &&
                              `${currencyFormatter(row.certs.EUR.netSum, 'EUR')} EUR`}
                          </Text>
                          <Link isExternal target='_blank' href={row.certs.EUR.url}>
                            {<DownloadIcon />}
                          </Link>
                        </Flex>
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        ) : (
          <Text textAlign={'center'} mt='50px'>
            There is no document available for this period.
          </Text>
        )}
      </Flex>
    </Card>
  );
}
