import { useCallback, useEffect, useState } from 'react';
import { Box, Button, Text, Link } from '@chakra-ui/react';
import { NavLink, useLocation, useSearchParams } from 'react-router-dom';
import { useGetClients } from 'api/clients';
import { ClientListFilterTypes } from './types';
import DevelopmentTable from 'components/DevelopmentTable';
import { Cell } from 'react-table';
import ClientListFilters from './components/ClientListFilters';
import moment from 'moment';
import useCountryList from 'hooks/useCountryList';
import { clearFilters } from 'utils/cleanFilters';
import { useListQueryContext } from 'contexts/useListQueryContext';
import ListTable from 'components/ListTable';
import { projectCategoryLabels } from './constants';

export default function ClientList() {
  const {getCountryName} = useCountryList();

  const {
    pageNumber,
    perPage,
    filters,
    setFilters,
    updateFilterSearchParams,
    sorting,
    setPageNumber
  } = useListQueryContext<ClientListFilterTypes>();

  const [
    { data: clientsData, loading, error },
    getClients,
  ] = useGetClients();

  const callGetClients = useCallback(
    async (
      page: number,
      perPage: number,
      sorting: { field: string; order: number },
      filters: ClientListFilterTypes,
    ) => {
      try {
        const cleanedFilters = clearFilters(filters)
        await getClients({
          params: {
            page,
            perPage,
            sorting,
            filters: cleanedFilters,
          },
        });

      } catch (e) {
        console.log(e);
      }
    },
    [getClients],
  );

  const handleSearch =( updatedFilters?: ClientListFilterTypes)=> {
    setPageNumber(1)
    callGetClients(1, perPage, sorting, updatedFilters || filters);
    updateFilterSearchParams(updatedFilters)
  }

  useEffect(() => {
    callGetClients(pageNumber, perPage, sorting, filters);
  }, [pageNumber, perPage, sorting]);


  const columnsDataClientUsers = {
    name: {
      columnDef: {
        Header: 'Company',
        accessor: 'name',
      },
    },
    country: {
      columnDef: {
        Header: 'Country',
        accessor: 'country',
      },
      renderer: (cell: Cell<any, any>) => {
        return (
          <Text color='black' fontWeight='bold'>
            {getCountryName(cell.value)}
          </Text>
        );
      },
    },
    industryCategory: {
      columnDef: {
        Header: 'Industry',
        accessor: 'industryCategory',
      },
      renderer: (cell: Cell<any, any>) => {
        return (
          <Text fontWeight='bold' color='black'>
            {projectCategoryLabels[cell.value]}
          </Text>
        );
      },
    },
    'createdAt': {
      columnDef: {
        Header: 'Date of application',
        accessor: 'createdAt',
      },
      renderer: (cell: Cell<any, any>) => {
        return (
          <Text fontWeight='bold' color='black'>
            {moment(cell.value).format('DD.MM.YYYY HH:mm')}
          </Text>
        );
      },
    },
    'adminVerified.name': {
      columnDef: {
        Header: 'Onboarding person',
        accessor: 'adminVerified.name',
      },
      renderer: (cell: Cell<any, any>) => {
        return (
          <Text fontWeight='bold' color='black'>{cell.value}</Text>
        );
      },
    },
    _id: {
      columnDef: {
        Header: 'Actions',
        accessor: '_id',
        width: 250,
      },
      renderer: (cell: Cell<any, any>) => {
        return (
          <Link as={NavLink} to={`/intel/client/${cell.value}`}>
            <Button variant='photonLight'>View</Button>
          </Link>
        );
      },
    },
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
          <ListTable
            variant='underlineRows'
            label={'Companies'}
            columnsData={Object.values(columnsDataClientUsers).map(
              (column: any) => column.columnDef,
            )}
            renderersData={columnsDataClientUsers}
            tableData={clientsData?.data}
            total={clientsData?.total}
            filterComp={
              <ClientListFilters
                filters={filters}
                setFilters={setFilters}
                handleSearch={handleSearch}
              />
            }
            isLoading={loading}
          />
    </Box>
  );
}
