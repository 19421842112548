import React, { useEffect, KeyboardEvent } from 'react';

import {
  Button,
  Grid,
  Flex,
  InputGroup,
  Text,
  Box,
  Select,
  Input,
  Checkbox,
} from '@chakra-ui/react';
import { ClientUserListFilterTypes } from '../types';
import { UserRoleLabels, UserRoles, UserStatus, UserStatusLabels } from '../constants';
import { useGetClientCountries } from 'api/clients';
import useCountryList from 'hooks/useCountryList';
import { RemoveInputContent } from 'components/RemoveInputContent';


const handleResetFilters = async (
  setFilters: React.Dispatch<React.SetStateAction<ClientUserListFilterTypes>>,
  handleSearch: (updatedFilters: ClientUserListFilterTypes) => Promise<void>,
) => {
  const updatedFilters = { search: '', status: '', country: '', brand: '', role:'', isMultibrandUser: false};
  await setFilters(updatedFilters);
  await handleSearch(updatedFilters);
};

const ClientUserListFilters = ({
  filters,
  handleSearch,
  setFilters,
  isActiveList,
}: {
  filters: ClientUserListFilterTypes;
  handleSearch: any;
  setFilters: React.Dispatch<React.SetStateAction<ClientUserListFilterTypes>>;
  isActiveList?: boolean
}) => {
  const [{ data: clientCountries }] = useGetClientCountries();
  const {getCountryName} = useCountryList();

  const handleFilterChange = (value: string | boolean, name: keyof ClientUserListFilterTypes) => {
    const updatedFilters = { ...filters, [name]: value };
    setFilters(updatedFilters as ClientUserListFilterTypes);
    if (name !== 'search' || !value) {
      handleSearch(updatedFilters as ClientUserListFilterTypes);
    }
  };

  const handleEnterPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch(filters);
    }
  };

  console.log("___", filters.isMultibrandUser)

  return (
    <Box w='100%'>
      <Grid
        templateColumns='repeat(auto-fit, minmax(320px, 1fr))'
        gap='0px 0px'
        justifyItems='center'
        alignItems='center'
        p='0px'
      >
        <Flex direction='column' mb={4}>
          <Text>Name/email:</Text>
          <InputGroup w='300px'>
            {filters.search && (
              <RemoveInputContent handleFilterChange={handleFilterChange} name={'search'} />
            )}
            <Input
              variant='tableSearch'
              placeholder='Search by name'
              onChange={(e) => handleFilterChange(e.target.value, 'search')}
              value={filters.search}
              onKeyDown={handleEnterPress}
              onBlur={() => handleSearch(filters)}
            />
          </InputGroup>
        </Flex>
        <Flex direction='column' mb={4} width='300px'>
          <Text>Country:</Text>
           <Select
            placeholder='Select country'
            value={filters.country}
            onChange={(e) => handleFilterChange(e.target.value, 'country')}
           >
            {clientCountries?.map((c: string)=> <option value={c} key={c}>{getCountryName(c)} </option>)}
           </Select>
        </Flex>
        <Flex direction='column' mb={4}>
          <Text>Company</Text>
          <InputGroup>
            {filters.brand && (
              <RemoveInputContent handleFilterChange={handleFilterChange} name={'brand'} />
            )}
            <Input
              paddingLeft='30px'
              variant='tableSearch'
              placeholder='Search by company'
              onChange={(e) => handleFilterChange(e.target.value, 'brand')}
              value={filters.brand}
              onKeyDown={handleEnterPress}
              onBlur={() => handleSearch(filters)}
            />
          </InputGroup>
        </Flex>
        <Flex direction='column' mb={4} width='300px'>
          <Text>Role:</Text>
          <Select
            onChange={(e) => handleFilterChange(e.target.value, 'role')}
            placeholder='Select role'
            value={filters.role}
          >
            {Object.values(UserRoles).map((r)=>(
                <option key={r} value={r}>
                     {UserRoleLabels[r]}
                </option>                
            ))}
          </Select>
        </Flex>
        {
         isActiveList && 
         <Flex ml='10px' mt='5px' direction='column' mb='10px'>
            <Checkbox
              size={'lg'}
              isChecked={filters.isMultibrandUser}
              onChange={(e) => {
                handleFilterChange(e.target.checked, 'isMultibrandUser');
              }}
            >
              <Text fontSize='16px'>
                List only multibrand users
              </Text>
            </Checkbox>
          </Flex>
        }
        <Flex justifyContent={{ base: 'center', md: 'start' }} w='300px'>
          <Button
            onClick={() => handleResetFilters(setFilters, handleSearch)}
            variant='photonMedium'
            w='130px'
          >
            Reset filters
          </Button>
        </Flex>
      </Grid>
    </Box>
  );
};
export default ClientUserListFilters;
