import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Text,
  Flex,
  Select,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useUpdateCreative } from '../../../api/staff-api';
import { useParams } from 'react-router-dom';
import {
  CheckIcon,
  CloseIcon,
} from '@chakra-ui/icons';
import {useEffect} from 'react';
import { useFormContext } from 'contexts/useFormContext';
import useCountryList from 'hooks/useCountryList';
import { CountryItem } from 'services/country-list';


export default function CreativeBillingData(props: { creativeData: any }) {
  const { setIsFormDirty, shouldReset, setShouldReset } = useFormContext();
  const { creativeData } = props;
  const { creativeId } = useParams();
  const toast = useToast();
  const {countryList} = useCountryList();

  const defaultValues = {
    billingName: creativeData.billingName,
    billingAddress: creativeData.billingAddress,
    billingAddressObj: creativeData.billingAddressObj,
    billingInvoicingForm:
      creativeData.billingInvoicingForm === 'TBD' ? '0%' : creativeData.billingInvoicingForm,
    taxNumber: creativeData.taxNumber,
    bank: creativeData.bank,
    accountNumber: creativeData.accountNumber,
    accountIban: creativeData.accountIban,
    accountCode: creativeData.accountCode,
    billingLanguage: creativeData.billingLanguage,
  };

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, isDirty },
    reset,
    getValues,
    watch
  } = useForm({
    defaultValues,
  });

  const [
    {
      error: updateCreativeError
    },
    updateCreative,
  ] = useUpdateCreative(creativeId as string);

  const billingLanguage = getValues('billingLanguage');

  useEffect(
    () => {
      if (updateCreativeError) {
        toast({
          title: 'Update was successfull',
          status: 'error',
          duration: 3000,
        });
      }
    },
    [updateCreativeError]
  );

  const handleSaveClick = async (data: any) => {
    await updateCreative({
        data,
    });
    setIsFormDirty(false);
    toast({
      title: 'Update was successfull',
      status: 'success',
      duration: 3000,
    });
  };

  useEffect(() => {
    if (isDirty) {
      setShouldReset(false);
      setIsFormDirty(isDirty);
    }
  }, [isDirty]);

  useEffect(() => {
    if (shouldReset) reset(defaultValues);
  }, [shouldReset]);

  const billingCountry = watch('billingAddressObj.country')

  return <Box mt='50px'>
    <form onSubmit={handleSubmit(handleSaveClick)}>
      <Flex
        justifyContent='space-around'
        alignItems={{ base: 'center', md: 'start' }}
        direction={{ base: 'column', md: 'row' }}
      >
        <Flex direction='column'>
          <FormControl id='billingName' mb={4} isInvalid>
            <FormLabel>Billing name</FormLabel>
            <Input variant='main' disabled {...register('billingName')} />
          </FormControl>

          <FormControl mb={4} w={{ base: '90vw', md: '500px' }}>
            <FormLabel>Country</FormLabel>
            <Select
              {...register('billingAddressObj.country')}
              w={{ base: '90vw', md: '500px' }}
              h='40px'
              bg='white'
              borderRadius='15px'
              value={billingCountry}
            >
            {countryList.map((country: CountryItem) => (
              <option key={country.value} value={country.value}>
                {country.label}
              </option>
            ))}
            </Select>
          </FormControl>

          <FormControl id='billingAddress' mb={4}>
            <FormLabel>Billing address</FormLabel>
            <Input variant='main' {...register('billingAddress')} />
          </FormControl>

          <FormControl alignItems='center' m='5px 0'>
            <FormLabel>Billing language</FormLabel>
            <Select
              {...register('billingLanguage')}
              display='flex'
              alignItems='center'
              bg='white'
              borderRadius='15px'
            >
              <option value='en'>English</option>
              <option value='hu'>Hungarian</option>
            </Select>
          </FormControl>

          <FormControl display='flex' alignItems='center' m='15px 0' id='autoBilling'>
            <FormLabel>Automatic billing:</FormLabel>
            <Box mb='10px'>
              <Text>
                {billingLanguage === 'hu' ? 'Billingo integration' : 'AutoBilling'}
                &nbsp;
                {creativeData.autoBilling?.active ? <CheckIcon /> : <CloseIcon />}
              </Text>
            </Box>
          </FormControl>
        </Flex>

        <Flex direction='column'>
          <FormControl id='billingInvoicingForm' mb={4}>
            <FormLabel>Billing invoicing form</FormLabel>
            <Input variant='main' {...register('billingInvoicingForm')} />
          </FormControl>

          <FormControl id='taxNumber' mb={4}>
            <FormLabel>Tax number</FormLabel>
            <Input variant='main' {...register('taxNumber')} />
          </FormControl>

          <FormControl id='bank' mb={4}>
            <FormLabel>Bank</FormLabel>
            <Input variant='main' {...register('bank')} />
          </FormControl>

          <FormControl id='accountNumberIban' mb={4}>
            <FormLabel>Account Number (IBAN)</FormLabel>
            <Input variant='main' {...register('accountIban')} />
          </FormControl>

          <FormControl id='accountNumber' mb={4}>
            <FormLabel>Account Number</FormLabel>
            <Input variant='main' {...register('accountNumber')} />
          </FormControl>

          <FormControl id='accountCode' mb={4}>
            <FormLabel>SWIFT/BIC</FormLabel>
            <Input variant='main' {...register('accountCode')} />
          </FormControl>

        </Flex>
      </Flex>

      <Flex justifyContent='center' m='50px'>
        <Button isLoading={isSubmitting} type='submit' variant='photonDark' w='200px'>
          Save
        </Button>
      </Flex>
    </form>
  </Box>;
}
