import React, {useEffect} from "react";

import {Box, Button, Flex, useToast,} from "@chakra-ui/react";
import {AiFillBank} from "react-icons/ai";
import {MdCancel} from 'react-icons/md';

import {
  useCancelTransfer,
  useFundTransfer,
  useGetTransfers,
} from '../../../api/transfers'
import LoadingModal from "components/LoadingModal";
import TransferTile from "./TransferTile";


interface FundButtonProps {
  transfer: Transfer,
  callback: Function,
}

type Transfer = {
  id: string;
  info: string;
  status: string;
  isFundable: boolean;
};

const FundButton: React.FC<FundButtonProps> = ({transfer, callback}) => {
  const toast = useToast();
  const [
    {
      loading: fundTransfersLoading,
      error: fundTransfersError,
    },
    fundTransfer,
  ] = useFundTransfer(transfer.id);

  useEffect(
    () => {
      if (fundTransfersError) {
        console.error(fundTransfersError);
        toast({
          title: 'Error funding transfer.',
          description: fundTransfersError.message,
          status: 'error',
          isClosable: true,
        });
      }
    },
    [fundTransfersError, toast],
  );

  useEffect(() => {
    if (!fundTransfersLoading) {
      callback();
    }
  }, [fundTransfersLoading]);

  return <Button
    variant='photonDark'
    leftIcon={<AiFillBank/>}
    onClick={fundTransfer}
    isLoading={fundTransfersLoading}
  >
    Fund
  </Button>
}

interface CancelTransferButtonProps {
  tid: string,
  callback?: Function,
}

const CancelTransferButton: React.FC<CancelTransferButtonProps> = ({tid, callback}) => {
  const toast = useToast();
  const [
    {
      loading: cancelTransferLoading,
      error: cancelTransferError,
    },
    cancelTransfer,
  ] = useCancelTransfer(tid);

  useEffect(
    () => {
      if (cancelTransferError) {
        console.error(cancelTransferError);
        toast({
          title: 'Error cancel transfer.',
          description: cancelTransferError.message,
          status: 'error',
          isClosable: true,
        });
      }
    },
    [cancelTransferError, toast],
  );

  useEffect(() => {
    if (!cancelTransferLoading) {
      callback();
    }
  }, [cancelTransferLoading]);

  return <Button
    variant="ghost"
    leftIcon={<MdCancel/>}
    onClick={() => cancelTransfer()}
    isLoading={cancelTransferLoading}
  >
    Cancel
  </Button>
}

export default function TransferList() {
  const [
    {
      data: transfers,
      loading: getTransfersLoading,
      error: getTransfersError,
    },
    reloadTransfers,
  ] = useGetTransfers();

  const toast = useToast();

  useEffect(
    () => {
      if (getTransfersError) {
        toast({
          title: 'Error getting transfers.',
          status: 'error',
          isClosable: true,
        });
      }
    },
    [getTransfersError]
  );

  return <Flex
    pt={{base: "50px", md: "80px", xl: "130px"}}
    h="90vh"
    flexWrap='wrap'
    gap='10px'
    justifyContent='space-around'
    alignContent='start'
  >
    {
      getTransfersError
      && <Box p='20px' border='1px solid red' backgroundColor='lightgray' maxH='200px'>
        Error during getting data: {getTransfersError.message}
      </Box>
    }
    {
      getTransfersLoading
      && <LoadingModal/>
    }
    {
      transfers
      && transfers.map(
        (transfer: Transfer) => <TransferTile
          title={transfer.info}
          info={transfer.status}
          fundButton={transfer.isFundable && <FundButton transfer={transfer} callback={reloadTransfers}/>}
          cancelButton={transfer.isFundable && <CancelTransferButton tid={transfer.id} callback={reloadTransfers}/>}
        />
      )
    }
  </Flex>
};
