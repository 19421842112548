import {
  Button,
  Grid,
  Flex,
  InputGroup,
  Input,
  Select,
  Text,
  Box
} from '@chakra-ui/react';
import { ClientListFilterTypes } from '../types';
import { KeyboardEvent } from 'react';
import React from 'react';
import { useGetClientCountries } from 'api/clients';
import useCountryList from 'hooks/useCountryList';
import { ProjectCategory, projectCategoryLabels } from '../constants';
import { RemoveInputContent } from 'components/RemoveInputContent';
import { sortCountries } from 'utils/sort-countries';

const statuses = [
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
];


const handleResetFilters = async (
  setFilters: React.Dispatch<React.SetStateAction<ClientListFilterTypes>>,
  handleSearch: (resetFilters: ClientListFilterTypes) => void,
  filters: ClientListFilterTypes,
) => {
  const updatedFilters: any = {};
  for (const key in filters) {
    updatedFilters[key] = '';
  }
  setFilters(updatedFilters);
  handleSearch(updatedFilters);
};

const CreativeListFilters = ({
  filters,
  handleSearch,
  setFilters,
}: {
  filters: ClientListFilterTypes;
  handleSearch: (resetFilters: ClientListFilterTypes) => void;
  setFilters: React.Dispatch<React.SetStateAction<ClientListFilterTypes>>;
}) => {
  const {getCountryName} = useCountryList();
  const [{ data: clientCountries }] = useGetClientCountries();
  const sortedCountries = sortCountries(clientCountries)

  const handleFilterChange = (value: string, name: keyof ClientListFilterTypes) => {
    const updatedFilters = { ...filters, [name]: value };
    setFilters(updatedFilters as ClientListFilterTypes);
    if (name !== 'search' || !value) {
      handleSearch(updatedFilters as ClientListFilterTypes);
    }
  };

  const handleEnterPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch(filters);
    }
  };

  return (
    <Box w='100%'>
        <Grid
          templateColumns='repeat(auto-fit, minmax(300px, 1fr))'
          gap='10px 10px'
          m='0px 60px 0px 20px'
          justifyItems='center'
          alignItems='center'
        >
          {'search' in filters && (
            <Flex direction='column' mb={4}>
              <Text>Company:</Text>
              <InputGroup w='300px'>
                {filters.search && (
                  <RemoveInputContent handleFilterChange={handleFilterChange} name={'search'} />
                )}
                <Input
                  variant='tableSearch'
                  placeholder='Search by name or email'
                  onChange={(e) => handleFilterChange(e.target.value, 'search')}
                  value={filters.search}
                  onBlur={() => handleSearch(filters)}
                  onKeyDown={handleEnterPress}
                />
              </InputGroup>
            </Flex>
          )}
          {'country' in filters && (
            <Flex direction='column' mb={4} width='300px'>
              <Text>Country:</Text>
              <Select
                onChange={(e) => handleFilterChange(e.target.value, 'country')}
                placeholder='Select country'
                value={filters.country}
              >
                {sortedCountries?.map((country: string) => (
                  <option key={country} value={country}>
                    {getCountryName(country)}
                  </option>
                ))}
              </Select>
            </Flex>
          )}
          {'industry' in filters && (
            <Flex direction='column' mb={4} width='300px'>
              <Text>Industry:</Text>
              <Select
                onChange={(e) => handleFilterChange(e.target.value, 'industry')}
                placeholder='All'
                value={filters.industry}
              >
                {Object.values(ProjectCategory).map((c: string) => (
                  <option key={c} value={c}>
                    {projectCategoryLabels[c]}
                  </option>
                ))}
              </Select>
            </Flex>
          )}
          {'status' in filters && (
            <Flex direction='column' mb={4} width='300px'>
              <Text>Status:</Text>
              <Select
                onChange={(e) => handleFilterChange(e.target.value, 'status')}
                placeholder='All'
                value={filters.status}
              >
                {statuses.map((status: { value: string; label: string }) => (
                  <option key={status.value} value={status.value}>
                    {status.label}
                  </option>
                ))}
              </Select>
            </Flex>
          )}
        </Grid>
        <Flex direction='row' width='300px' m='10px 0px 10px 20px'>
          <Button
            onClick={() => handleResetFilters(setFilters, handleSearch, filters)}
            variant='photonMedium'
            ml='20px'
            w='130px'
          >
            Reset filters
          </Button>
        </Flex>
    </Box>
  );
};
export default CreativeListFilters;
