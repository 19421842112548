import { useCallback, useEffect } from 'react';
import { Box, Button, Link, Text } from '@chakra-ui/react';
import { NavLink} from 'react-router-dom';
import { Cell } from 'react-table';
import { useGetClientUsers } from 'api/client-users';
import { ClientUserListFilterTypes } from '../types';
import ClientUserListFilters from './ClientUserListFilters';
import { UserRoleLabels, UserStatusLabels } from '../constants';
import useCountryList from 'hooks/useCountryList';
import { MdClose, MdDone } from "react-icons/md";
import { useListQueryContext } from 'contexts/useListQueryContext';
import ListTable from 'components/ListTable';
import { clearFilters } from 'utils/clear-filters';

export default function ActiveClientUsers() {
  const {getCountryName} = useCountryList();

  const {
    pageNumber,
    perPage,
    filters,
    setFilters,
    updateFilterSearchParams,
    sorting,
    setPageNumber
  } = useListQueryContext<ClientUserListFilterTypes>();

  const [
    { data: editorsData, loading, error },
    getClientUsers,
  ] = useGetClientUsers();

  const callGetClientUsers = useCallback(
    async (
      page: number,
      perPage: number,
      sorting: { field: string; order: number },
      filters: ClientUserListFilterTypes,
    ) => {
      try {
        const updatedFilters = clearFilters(filters)
  
        await getClientUsers({
          params: {
            page,
            perPage,
            sorting,
            filters: updatedFilters,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
    [getClientUsers],
  );
  

  const handleSearch =( updatedFilters?: ClientUserListFilterTypes)=> {
    setPageNumber(1)
    callGetClientUsers(1, perPage, sorting, updatedFilters || filters);
    updateFilterSearchParams(updatedFilters)
  }

  useEffect(() => {
    callGetClientUsers(pageNumber, perPage, sorting, filters);
  }, [pageNumber, perPage, sorting]);


  const columnsDataClientUsers = {
    name: {
      columnDef: {
        Header: 'Name',
        accessor: 'name',
      },
    },
    email: {
      columnDef: {
        Header: 'Email',
        accessor: 'email',
      },
    },
    phone: {
      columnDef: {
        Header: 'Phone',
        accessor: 'phone',
      },
    },
    company: {
        columnDef: {
          Header: 'Company',
          accessor: 'brandName',
        },
        renderer: (cell: Cell<any, any>) => {
          return (
            <Link as={NavLink} to={`/intel/client/${cell.value.plus.brand.id}`}>
              <Text fontWeight='bold' color='black'>{cell.value.plus.brand.brandName}</Text>
            </Link>
          );
        },
      },
      country: {
        columnDef: {
          Header: 'Country',
          accessor: 'country',
        },
        renderer: (cell: Cell<any, any>) => {
          return (
            <Text color='black' fontWeight='bold'>
              {getCountryName(cell.value)}
            </Text>
          );
        },
      },
    role: {
        columnDef: {
          Header: 'Role',
          accessor: 'role',
        },
        renderer: (cell: Cell<any, any>) => {
          return (
            <Text color='black' fontWeight='bold'>
              {UserRoleLabels[cell.value]}
            </Text>
          );
        },
      },
      'plus.brands': {
        columnDef: {
          Header: 'Multibrand user',
          accessor: 'plus.brands',
        },
        renderer: (cell: Cell<any, any>) => {
          return (
            <Text color='black' fontWeight='bold'>
              {cell.value?.length > 0 ? <MdDone size='20'/> : <MdClose size='20'/>}
            </Text>
          );
        },
      },
    _id: {
      columnDef: {
        Header: 'Actions',
        accessor: '_id',
        width: 250,
      },
      renderer: (cell: Cell<any, any>) => {
        return (
          <Link as={NavLink} to={`/intel/client/user/${cell.value}`}>
            <Button variant='photonLight'>View</Button>
          </Link>
        );
      },
    },
  };

  return (
    <Box>
      <ListTable
        variant='underlineRows'
        columnsData={Object.values(columnsDataClientUsers).map(
          (column: any) => column.columnDef,
        )}
        renderersData={columnsDataClientUsers}
        tableData={editorsData?.data}
        total={editorsData?.total}
        filterComp={
          <ClientUserListFilters
            filters={filters}
            setFilters={setFilters}
            handleSearch={handleSearch}
            isActiveList = {true}
          />
        }
        isLoading={loading}
      />
    </Box>
  );
}
