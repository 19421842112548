import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import ActiveClientUsers from './components/ActiveClientUsers';
import InvitedClientUsers from './components/InvitedClientUsers';
import DeletedClientUsers from './components/DeletedClientUsers';
import { useSearchParams } from 'react-router-dom';
import { ListQueryProvider } from 'contexts/useListQueryContext';
import { ClientUserListFilterTypes } from './types';

export default function ClientUserList() {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleTabChange = () => {
    setSearchParams({});
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Text fontSize='25px' fontWeight='bold' m='20px'>
        Client users
      </Text>
      <Tabs onChange={handleTabChange}>
        <TabList ml='15px'>
          <Tab>Active</Tab>
          <Tab>Invited</Tab>
          <Tab>Deleted</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <ListQueryProvider<ClientUserListFilterTypes >
              defaultFilters={{
                search: '',
                brand: '',
                country: '',
                role: '',
                isMultibrandUser: false
              }}
            >
              <ActiveClientUsers />
            </ListQueryProvider>
          </TabPanel>
          <TabPanel>
            <ListQueryProvider<ClientUserListFilterTypes >
              defaultFilters={{
                search: '',
                brand: '',
                country: '',
                role: '',
              }}
            >
              <InvitedClientUsers />
            </ListQueryProvider>
          </TabPanel>
          <TabPanel>
            <ListQueryProvider<ClientUserListFilterTypes >
              defaultFilters={{
                search: '',
                brand: '',
                country: '',
                role: '',
              }}
            >
              <DeletedClientUsers />
            </ListQueryProvider>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

