export const ProjectCategory = {
    EVENT: 'event',
    FOOD: 'food',
    HOSPITALITY: 'hospitality',
    E_COMMERCE: 'ecommerce',
    OTHER: 'other',
};

export const projectCategoryLabels = {
    [ProjectCategory.EVENT]: 'Events',
    [ProjectCategory.FOOD]: 'Foodtech',
    [ProjectCategory.HOSPITALITY]: 'Hospitality',
    [ProjectCategory.E_COMMERCE]: 'E-commerce',
    [ProjectCategory.OTHER]: 'Other'
}

export const ClientStatus = {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
}

export const clientStatusLabel = {
    [ClientStatus.ACTIVE]: 'Active',
    [ClientStatus.INACTIVE]: 'Inactive',
}


export const OrderAmountCategory = {
    LESS_THAN_15: 'lessThan15',
    BETWEEN_15_AND_25: '15to25',
    MORE_THAN_25: 'moreThan25',
    NOT_SURE: 'notSure',
    ONE_TIME: 'oneTime',
};

export const orderAmountCategoryLabels = {
    [OrderAmountCategory.LESS_THAN_15]: 'Less than 15',
    [OrderAmountCategory.BETWEEN_15_AND_25]: '15 to 25',
    [OrderAmountCategory.MORE_THAN_25]: 'More than 25',
    [OrderAmountCategory.NOT_SURE]: 'Not sure',
    [OrderAmountCategory.ONE_TIME]: 'One time',
}





