import React from "react";
import {Box, Heading, Text} from "@chakra-ui/react";

interface TileProps {
  title: string;
  info: string;
  fundButton?: React.ReactNode;
  cancelButton?: React.ReactNode;
}

const TransferTile: React.FC<TileProps> = ({title, info, fundButton, cancelButton}) => {
  return (
    <Box
      maxH='13rem'
      maxW='20rem'
      overflow='auto'
      borderWidth="1px"
      borderRadius="lg"
      p={6}
      boxShadow="md"
      backgroundColor="white"
    >
      <Heading as="h2" size="md" mb={4}>
        {title}
      </Heading>
      <Text mb={6} overflowWrap='break-word'>{info}</Text>
      {
        !!fundButton
        && fundButton
      }
      {
        !!cancelButton
        && cancelButton
      }
    </Box>
  );
};

export default TransferTile;
