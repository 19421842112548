import {CreativeEvent} from "../../types";

import React from "react";
import {
  Box,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import {createEventComponent} from "./EventComponentFactory";
import {CreativeEventType} from "../../constants";


interface Props {
  events: CreativeEvent[];
  rowsDisplayed?: number;
  title?: string;
  actions?: Map<string, Function>;
}

export const CreativeEvents: React.FC<Props> = ({events, title, rowsDisplayed= 10, actions}) => (
  <Box
    bg='white'
    borderRadius='20px' p='20px' m='20px' w='100%'
    minH={`${rowsDisplayed * 25}px`}
    maxH={`${rowsDisplayed * 50}px`}
    overflowY="auto"
  >
    {
      title
      && <Text fontWeight='bold' fontSize='20px'>
        {title}
      </Text>
    }

    <Table size='sm' m='10px'>
      <Thead textAlign='center'>
        <Tr borderBottom='solid #E2E8F0 2px'>
          <Th textAlign='center'>Event</Th>
          <Th textAlign='center'>Date</Th>
          <Th textAlign='center'>Updated by</Th>
          <Th textAlign='center'>Other info</Th>
        </Tr>
      </Thead>
      <Tbody>
        {
          events?.map(
            (event: CreativeEvent) => {
              if (event.event === CreativeEventType.ACCEPT) {
                event.details.actions ??= new Map();
                event.details.actions.set('getRegistrationLink', actions.get('getRegistrationLink'));
              }

              if (event.event === CreativeEventType.FINISH_REGISTRATION) {
                event.details ??= {actions: new Map()}
                event.details.actions.set('getConfirmationLink', actions.get('getConfirmationLink'));
              }
              return createEventComponent(event)
            }
          )
        }
      </Tbody>
    </Table>
  </Box>
);
