import React, { useCallback, useEffect, useState } from 'react';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Switch,
} from '@chakra-ui/react';
import { z } from 'zod';
import { AsyncSelect, OptionBase } from 'chakra-react-select';
import { adminRoles } from '../../variables/roles';
import {
  CreateRecruitment,
  UpdateRecruitment,
  useCreateRecruitment,
  useUpdateRecruitment,
} from 'api/recruitment';
import {
  getCityList,
  useLoadCityOptionsWithDebounce,
} from 'views/creative/helpers/getCities';

export const defaultForm = {
  _id: '',
  area: '',
  photographersWanted: 0,
  shortage: 0,
  isActive: false,
};

const validators = {
  area: z.string().min(3, 'Area is required').max(255, 'Must be less than 255 characters'),
  photographersWanted: z.number().nullable(),
};

const formValidator = z.object({
  area: validators.area,
  photographersWanted: validators.photographersWanted,
});

export interface SelectSchema extends OptionBase {
  label: string;
  value: string;
}

export const roleOptions = Object.entries(adminRoles).map(([_, { key, label }]) => ({
  label,
  value: key,
}));

export const roleKeyToLabelMap = new Map<string, string>(
  Object.entries(adminRoles).map(([_, { key, label }]) => [key, label]),
);

export default function UpdateOrCreateRecruitmentModal({
  selectedRecruitment,
  isOpen,
  onClose,
  toCreate,
}: {
  isOpen: boolean;
  onClose: (successfulUpdate?: boolean) => void;
  selectedRecruitment?: {
    _id: string;
    area: string;
    photographersWanted: number;
    isActive?: boolean;
  };
  toCreate?: boolean;
}) {
  const toast = useToast();
  const [cities, setCities] = useState([]);
  const [formData, setFormData] = useState({
    _id: selectedRecruitment?._id || defaultForm._id,
    area: selectedRecruitment?.area || defaultForm.area,
    photographersWanted:
      selectedRecruitment?.photographersWanted || defaultForm.photographersWanted,
    isActive: selectedRecruitment?.isActive || defaultForm.isActive,
  });
  const [errors, setErrors] = useState('');
  const loadCityOptionsWithDebounce = useLoadCityOptionsWithDebounce();

  const [{ loading: isUpdateInProgress, error }, updateRecruitment] = useUpdateRecruitment();
  const [{ loading: isCreateInProgress, error: createErrors }, createRecruitment] =
    useCreateRecruitment();

  useEffect(() => {
    if (selectedRecruitment) {
      setFormData({
        _id: selectedRecruitment?._id || defaultForm._id,
        area: selectedRecruitment?.area || defaultForm.area,
        photographersWanted:
          selectedRecruitment?.photographersWanted || defaultForm.photographersWanted,
        isActive: selectedRecruitment?.isActive || defaultForm.isActive,
      });
    }
  }, [selectedRecruitment]);

  useEffect(() => {
    if (error) {
      toast({
        title: 'Update is unsuccessful!',
        description: error.response?.data?.message,
        status: 'error',
        duration: 3000,
      });
    }
  }, [error, toast]);

  useEffect(() => {
    if (createErrors) {
      toast({
        title: 'Create was unsuccessful!',
        description: createErrors.response?.data?.message,
        status: 'error',
        duration: 3000,
      });
    }
  }, [createErrors, toast]);

  const callUpdate = useCallback(async () => {
    try {
      const recruitmentData = formData as UpdateRecruitment;

      try {
        formValidator.parse(formData);
      } catch (error: any) {
        setErrors(error.errors.map((e: any) => e.message).join('\n'));
        return;
      }

      await updateRecruitment({ data: recruitmentData });
      setFormData(defaultForm);
      onClose(true);
    } catch (e) {
      // handled
    }
  }, [formData, updateRecruitment, setFormData, onClose]);

  const validate = useCallback(
    (field: string) => () => {
      try {
        // @ts-ignore
        validators[field].parse(formData[field]);
      } catch (error: any) {
        setErrors(error.errors.map((e: any) => e.message).join('\n'));
        return;
      }
      setErrors('');
    },
    [formData],
  );

  const callCreate = useCallback(async () => {
    try {
      const recruitmentData = formData as CreateRecruitment;

      try {
        formValidator.parse(formData);
      } catch (error: any) {
        setErrors(error.errors.map((e: any) => e.message).join('\n'));
        return;
      }

      await createRecruitment({ data: recruitmentData });
      setFormData(defaultForm);
      onClose(true);
    } catch (e) {
      // handled
    }
  }, [formData, createRecruitment, setFormData, onClose]);

  const handleCityInputChange = (value: any) => getCityList(value);

  const handleAreaChange = (area: { value: string; label: string }) => {
    setFormData((prevState) => ({
      ...prevState,
      area: area.value,
    }));
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setFormData(defaultForm);
        setErrors('');
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {selectedRecruitment ? 'Update recruitment' : 'Create recruitment'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id='area' mb='10px'>
            <FormLabel>City</FormLabel>
            <AsyncSelect
              isDisabled={!!selectedRecruitment}
              required
              onInputChange={(value: any) => handleCityInputChange(value)}
              options={cities}
              loadOptions={loadCityOptionsWithDebounce}
              onChange={(e: any) => handleAreaChange(e)}
              getOptionValue={(option: any) => option.value}
              getOptionLabel={(option: any) => option.label}
              value={
                formData.area
                  ? { value: formData.area, label: formData.area }
                  : { value: '', label: 'Select area' }
              }
              components={{
                DropdownIndicator: () => null,
              }}
            />
          </FormControl>

          <FormControl id='photographersWanted' mb='10px'>
            <FormLabel>Photographers wanted</FormLabel>
            <Input
              value={formData.photographersWanted ?? 0}
              onChange={(e) =>
                setFormData((prev: any) => ({
                  ...prev,
                  photographersWanted: parseInt(e.target.value) || 0,
                }))
              }
              onBlur={validate('photographersWanted')}
            />
          </FormControl>
          <FormControl id='isActive'>
            <FormLabel>Active</FormLabel>
            <Switch
              isChecked={formData.isActive}
              colorScheme='brandScheme'
              size='md'
              onChange={(e) => setFormData((prev: any) => ({ ...prev, isActive: !prev.isActive }))}
            />
          </FormControl>
          {errors && (
            <Alert status='error' my={2}>
              <AlertIcon />
              <AlertTitle mr={2}>{errors}</AlertTitle>
            </Alert>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            mr={3}
            isLoading={isUpdateInProgress || isCreateInProgress}
            onClick={toCreate ? callCreate : callUpdate}
            variant='photonLight'
          >
            Save
          </Button>
          <Button
            variant='photonMedium'
            onClick={() => {
              setFormData(defaultForm);
              setErrors('');
              onClose();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
