import {countryLabels} from "lib/localized-countries/en";


export const sortCountries = (creativeCountries: string[]) => {
  return (
    creativeCountries
      ?.filter((cc: string) => !!cc)
      .sort(
        (aCountryCode: string, bCountryCode: string) => {
          const aLabel = countryLabels[aCountryCode] || aCountryCode;
          const bLabel = countryLabels[bCountryCode] || bCountryCode;

          return aLabel.localeCompare(bLabel)
        }
      )
  ) ?? [];
}
