import {useCallback, useState} from "react";
// @ts-ignore
import {create, Mode} from '@transferwise/approve-api-action-helpers';

import useAxios from "./basic/use-axios";
import config, {PROD_ENV} from "../config";
import {getAuthorizationToken} from "./basic/serverClient";


type FetchDataResult<T> = [
  {
    data: T | null;
    loading: boolean;
    error: Error | null;
  },
  callback: () => void
];

export const useFetch = <T, >(tid: string): FetchDataResult<T> => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const call = useCallback(
    () => {
      setLoading(true);
      setError(null);

      const fetchData = async () => {
        try {
          const request = create(
            {mode: (config.environment === PROD_ENV ? Mode.PRODUCTION : Mode.SANDBOX)}
          );

          const token = getAuthorizationToken();
          const response = await request(
            `${config.apiUrl}/admin/wise/transfer/${tid}/fund`,
            {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
              }
            },
          );
          setData(await response.json());
        } catch (err) {
          if (err instanceof Error) {
            // @ts-ignore
            const errorResponse = await err.response?.json?.();
            setError(new Error(errorResponse.message) || err);
          } else {
            setError(new Error("Unknown error occurred"));
          }
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    },
    [tid]
  );

  return [{data, loading, error}, call];
};


export const useGetTransfers = () => useAxios(
  {
    url: '/admin/wise/transfers',
    method: 'GET',
  },
);

export const useCancelTransfer = (tid: string) => useAxios(
  {
    url: `/admin/wise/transfer/${tid}/cancel`,
    method: 'PUT',
  },
  { manual: true },
);

export const useFundTransfer = (tid: string) => useFetch(tid);
