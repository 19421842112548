import { useEffect, useState } from "react";
import { ModalBody, ModalHeader } from "@chakra-ui/react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { ColumnType } from "views/projects/types";
import SortableColumn from "./SortableColumn";

function SortColumns({
  columns,
  onReorder,
}: {
  columns: ColumnType[];
  onReorder: (columns: ColumnType[]) => void;
}) {
  const [isDirty, setDirty] = useState(false);
  const [items, setItems] = useState(
    columns.map((column) => {
      return { ...column, id: column.Header };
    })
  );

  useEffect(() => {
    setItems(
      columns.map((column) => {
        return { ...column, id: column.Header };
      })
    );
  }, [columns]);

  useEffect(() => {
    if (isDirty) {
      onReorder(
        items.map((item) => {
          let { id: _, ...column } = item;

          return { ...column };
        })
      );

      setDirty(false)
    }
  }, [items]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });

      setDirty(true);
    }
  };

  return (
    <>
      <ModalHeader>Reorder visible columns</ModalHeader>

      <ModalBody>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={items} strategy={verticalListSortingStrategy}>
            {items
              .filter((column) => column.show && column.Header)
              .map((column) => (
                <SortableColumn key={column.Header} id={column.Header} />
              ))}
          </SortableContext>
        </DndContext>
      </ModalBody>
    </>
  );
}

export default SortColumns;
