import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    useToast,
    Flex,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Text,
  } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {useEffect, useState} from 'react';
import { useFormContext } from 'contexts/useFormContext';
import useCountryList from 'hooks/useCountryList';
import { useUpdateEditor } from 'api/editors';
import { MdModeEdit, MdOutlineDone } from "react-icons/md";
import { IoCloseSharp } from "react-icons/io5";
import { CustomField } from 'views/projects/components/ProjectBasicData';
import { CustomFieldStyle } from './EditorBilling';


  export default function EditorBasicData(props: { editorData: any }) {
    const { setIsFormDirty, shouldReset, setShouldReset } = useFormContext();
    const [editField, setEditField] = useState('');
    const { editorData } = props;
    const { editorId } = useParams();
    const toast = useToast();
    const {countryList} = useCountryList();

  
    const defaultValues = {
      name: editorData.name,
      email: editorData.email,
      phone: editorData.phone,
      notificationWeighting: editorData.editor.notificationWeighting || 1
    };
  
    const {
      handleSubmit,
      register,
      formState: {isDirty },
      reset,
      getValues,
      watch,
      setValue
    } = useForm({
      defaultValues,
    });
  
    const [
      {
        error: updateEditorError,
        loading
      },
      updateEditor,
    ] = useUpdateEditor(editorId as string);
  
    useEffect(
      () => {
        if (updateEditorError) {
          toast({
            title: 'Sikertelen mentés',
            status: 'error',
            duration: 3000,
          });
        }
      },
      [updateEditorError]
    );
  
    const handleSaveClick = async (data: any) => {
      await updateEditor({data:{...data, notificationWeighting: parseInt(data.notificationWeighting)}});
      setIsFormDirty(false);
      toast({
        title: 'Update was successfull',
        status: 'success',
        duration: 3000,
      });
    };
  
    useEffect(() => {
      if (isDirty) {
        setShouldReset(false);
        setIsFormDirty(isDirty);
      }
    }, [isDirty]);
  
    useEffect(() => {
      if (shouldReset) reset(defaultValues);
    }, [shouldReset]);
  
    const watchWeighting = watch('notificationWeighting')
  
    return <Box mt='50px'>
      <form onSubmit={handleSubmit(handleSaveClick)}>
        <Flex
          justifyContent='space-around'
          alignItems={{ base: 'center', md: 'start' }}
          direction={{ base: 'column', md: 'row' }}
        >
          <Flex direction='column'>
            <CustomField
              label='Name'
              id='name'
              register={register}
              getValues={getValues}
              setEditField={setEditField}
              editField={editField}
            />
            <CustomField
              label='Email'
              id='email'
              register={register}
              getValues={getValues}
              setEditField={setEditField}
              editField={editField}
            />
            <CustomField
              label='Phone'
              id='phone'
              register={register}
              getValues={getValues}
              setEditField={setEditField}
              editField={editField}
            />
            <FormControl isInvalid>
              <FormLabel display='flex' justifyContent={'space-between'}>
                Project notifications related weighting
              </FormLabel>

              <Flex>
                {editField === 'notificationWeighting' ? (
                  <NumberInput 
                    onChange={(e)=>setValue('notificationWeighting', e)} 
                    defaultValue={watchWeighting} 
                    min={1} 
                    max={10} 
                    step={1} 
                    bg='white' 
                    onBlur={()=>setEditField('')}
                    w='400px'
                    mb='15px'
                  >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                ) : (
                  <>
                    <Text {...CustomFieldStyle} w='400px'>{watchWeighting}</Text>
                    <Button ml='5px' onClick={() => setEditField('notificationWeighting')}>
                      <MdModeEdit size={20}/>
                    </Button>

                  </>
                )}
              </Flex>
            </FormControl>

            <Flex justifyContent={'space-between'}>
              <FormLabel>Newsletter</FormLabel>
              {editorData.newsletter ? <MdOutlineDone/> : <IoCloseSharp />}
            </Flex>
            <Flex justifyContent={'space-between'}>
              <FormLabel>Privacy Notice</FormLabel>
              {editorData.pn ? <MdOutlineDone/> : <IoCloseSharp />}
            </Flex>
            <Flex justifyContent={'space-between'}>
              <FormLabel>Terms & Conditions</FormLabel>
              {editorData.tandc ? <MdOutlineDone/> : <IoCloseSharp />}
            </Flex>
          </Flex>
        </Flex>
        <Flex justifyContent='center' m='50px'>
          <Button isLoading={loading} type='submit' variant='photonDark' w='200px'>
            Save
          </Button>
        </Flex>
      </form>
    </Box>;
  }
  