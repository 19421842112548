import moment from "moment";

function getTimeDifferenceInHours(projectIanaCode: string) {
  const ianaCode = projectIanaCode ?? "Europe/Budapest";

  const budapestTime = moment.tz("Europe/Budapest");
  const ianaTime = moment.tz(ianaCode);
  const budapestOffset = budapestTime.utcOffset();
  const ianaOffset = ianaTime.utcOffset();
  const utcOffsetDifference = ianaOffset - budapestOffset;
  
  const timeDifferenceInHours = utcOffsetDifference / 60;

  return { timeDifferenceInHours, ianaCode };
}

export default getTimeDifferenceInHours;
