import { useCallback, useEffect } from 'react';
import { Box, Link, Text } from '@chakra-ui/react';
import { NavLink,} from 'react-router-dom';
import { Cell } from 'react-table';
import { useGetInvitedUsers } from 'api/client-users';
import useCountryList from 'hooks/useCountryList';
import { ClientUserListFilterTypes } from '../types';
import { UserRoleLabels } from '../constants';
import ClientUserListFilters from './ClientUserListFilters';
import moment from 'moment';
import { useListQueryContext } from 'contexts/useListQueryContext';
import ListTable from 'components/ListTable';
import { clearFilters } from 'utils/clear-filters';

export default function InvitedClientUsers() {
  const {getCountryName} = useCountryList();

  const {
    pageNumber,
    perPage,
    filters,
    setFilters,
    updateFilterSearchParams,
    sorting,
    setPageNumber
  } = useListQueryContext<ClientUserListFilterTypes>();

  const [
    { data: usersData, loading, error },
    getClientUsers,
  ] = useGetInvitedUsers();
  

  const callGetClientUsers = useCallback(
    async (
      page: number,
      perPage: number,
      sorting: { field: string; order: number },
      filters: ClientUserListFilterTypes,
    ) => {
      try {
        const updatedFilters = clearFilters(filters)
  
        await getClientUsers({
          params: {
            page,
            perPage,
            sorting,
            filters: updatedFilters,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
    [getClientUsers],
  );
  
  const handleSearch =( updatedFilters?: ClientUserListFilterTypes)=> {
    setPageNumber(1)
    callGetClientUsers(1, perPage, sorting, updatedFilters || filters);
    updateFilterSearchParams(updatedFilters)
  }

  useEffect(() => {
    callGetClientUsers(pageNumber, perPage, sorting, filters);
  }, [pageNumber, perPage, sorting]);

  const columnsDataClientUsers = {
    name: {
      columnDef: {
        Header: 'Name',
        accessor: 'name',
      },
    },
    email: {
      columnDef: {
        Header: 'Email',
        accessor: 'email',
      },
    },
    phone: {
      columnDef: {
        Header: 'Phone',
        accessor: 'phone',
      },
    },
    company: {
        columnDef: {
          Header: 'Company',
          accessor: 'brandName',
        },
        renderer: (cell: Cell<any, any>) => {
          return (
            <Link as={NavLink} to={`/intel/client/${cell.value.plus.brand.id}`}>
              <Text fontWeight='bold' color='black'>{cell.value.plus.brand.brandName}</Text>
            </Link>
          );
        },
      },
      country: {
        columnDef: {
          Header: 'Country',
          accessor: 'country',
        },
        renderer: (cell: Cell<any, any>) => {
          return (
            <Text color='black' fontWeight='bold'>
              {getCountryName(cell.value)}
            </Text>
          );
        },
      },
    role: {
        columnDef: {
          Header: 'Role',
          accessor: 'role',
        },
        renderer: (cell: Cell<any, any>) => {
          return (
            <Text color='black' fontWeight='bold'>
              {UserRoleLabels[cell.value]}
            </Text>
          );
        },
      },
      invitedByName: {
        columnDef: {
          Header: 'Invited by',
          accessor: 'invitedByName',
        },
      },
      inviteLink: {
        columnDef: {
          Header: 'Invite link',
          accessor: 'inviteLink',
        },
        renderer: (cell: Cell<any, any>) => {
          return (
            <Link as={NavLink} to={cell.value} target='_blank'>
              <Text fontWeight='bold' color='black'>Link</Text>
            </Link>
          );
        },
      },
      createdAt: {
        columnDef: {
          Header: 'Date of invite',
          accessor: 'createdAt',
        },
        renderer: (cell: Cell<any, any>) => {
          return (
              <Text m='20px'fontWeight='bold' color='black'>{moment(cell.value).format('HH:mm DD.MM.YYYY')}</Text>
          );
        },
      },
  };

  return (
    <Box>
          <ListTable
            variant='underlineRows'
            columnsData={Object.values(columnsDataClientUsers).map(
              (column: any) => column.columnDef,
            )}
            renderersData={columnsDataClientUsers}
            tableData={usersData?.data}
            total={usersData?.total}
            filterComp={
              <ClientUserListFilters
                filters={filters}
                setFilters={setFilters}
                handleSearch={handleSearch}
              />
            }
            isLoading={loading}
          />
    </Box>
  );
}
