import {Select} from '@chakra-ui/react';
import {useGetClientCountries} from 'api/clients';
import useCountryList from 'hooks/useCountryList';
import {sortCountries} from 'utils/sort-countries';

export default function CountrySelector(props: { country: string; setCountry: Function }) {
  const {country, setCountry} = props;
  const {getCountryName} = useCountryList();

  const handleCountryChange = (country: string) => {
    setCountry(country);
  };
  const [{data: countries}] = useGetClientCountries();
  const sortedCountries = sortCountries(countries)

  return (
    <Select
      placeholder='All countries'
      value={country}
      bg='white'
      onChange={(e) => handleCountryChange(e.target.value)}
      w='170px'
      m='5px'
    >
      {sortedCountries?.map((countryCode: string) => {
        return (
          countryCode && (
            <option value={countryCode} key={countryCode}>
              {getCountryName(countryCode)}
            </option>
          )
        );
      })}
    </Select>
  );
}
