import React from "react";

import {Box, Spinner} from "@chakra-ui/react";


const LoadingModal: React.FC = () => {
  return <Box
    position="absolute"
    zIndex={10}
    top={0}
    bottom={0}
    left={0}
    right={0}
    backgroundColor="whiteAlpha.700" // 40% transparency
    display="flex"
    alignItems="center"
    justifyContent="center"
    transform="scale(1)"
  >
    <Spinner
      thickness='4px'
      speed='0.65s'
      emptyColor='gray.200'
      color='brand.600'
      size='xl'
    />
  </Box>
}

export default LoadingModal;
