import { Tag } from "@chakra-ui/react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "@emotion/styled";

const StyledTag = styled(Tag)`
  font-size: 16px;
`;

function SortableColumn({ id }: { id: string }) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <StyledTag key={id} id={id}>
        {id}
      </StyledTag>
    </div>
  );
}

export default SortableColumn;
