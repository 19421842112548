import { useSearchParams } from 'react-router-dom';
import { 
    useState, 
    createContext, 
    ReactElement, 
    useMemo, 
    useContext, 
    Dispatch, 
    SetStateAction, 
    useEffect
} from 'react';

interface SortingType {
  field: string;
  order: number;
}

interface ListQueryContextType<T> {
  pageNumber: number;
  perPage: number;
  filters: T;
  setFilters: Dispatch<SetStateAction<T>>;
  sorting: SortingType;
  handleSorting: (column: any) => void;
  updateFilterSearchParams: (updatedFilters: Partial<T>) => void;
  setPerPage: React.Dispatch<React.SetStateAction<number>>
  setPageNumber: React.Dispatch<React.SetStateAction<number>>
}

const ListQueryContext = createContext<ListQueryContextType<any> | null>(null);

export const ListQueryProvider = <T extends Record<string, any>>({
  children,
  defaultFilters,
  defaultSortField = 'name',
  defaultSortOrder = 1,
  defaultPerPage = 25,
}: {
  children: ReactElement;
  defaultFilters: T;
  defaultSortField?: string;
  defaultSortOrder?: number;
  defaultPerPage?: number;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageNumber, setPageNumber] = useState(parseInt(searchParams.get('pageNumber')!) || 1);
  const [perPage, setPerPage] = useState(parseInt(searchParams.get('perPage')!) || defaultPerPage);

  const [sorting, setSorting] = useState<SortingType>({
    field: searchParams.get('sort') || defaultSortField,
    order: parseInt(searchParams.get('order')!) || defaultSortOrder,
  });

  const [filters, setFilters] = useState<T>(() => {
    const extractedFilters: Partial<T> = {};
    Object.keys(defaultFilters).forEach((key) => {
      const queryValue = searchParams.get(key);
      extractedFilters[key as keyof T] = queryValue !== null ? (queryValue as unknown as T[keyof T]) : defaultFilters[key as keyof T];
    });
    return extractedFilters as T;
  });

  const updateFilterSearchParams = (updatedFilters: Partial<T>) => {
    setSearchParams((params) => {
      Object.entries(updatedFilters).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
          params.set(key, encodeURIComponent(String(value)));
        } else {
          params.delete(key);
        }
      });
      return params;
    });
  };

  const handleSorting = (column: any) => {
    const columnId = column.id;
    const isColumnAlreadySorted = sorting.field === columnId;
    const order = isColumnAlreadySorted ? sorting.order * -1 : -1;

    setSearchParams((params) => {
      params.set('sort', columnId);
      params.set('order', order as unknown as string);
      return params;
    });
    setPageNumber(1);
    setSorting({ field: columnId, order });
  };

  useEffect(() => {
    setSearchParams((params) => {
      params.set('pageNumber', pageNumber.toString());
      return params;
    });
  }, [pageNumber]);

  useEffect(() => {
    setSearchParams((params) => {
      params.set('perPage', perPage.toString());
      return params;
    });
    setPageNumber(1)
  }, [perPage]);


  const value = useMemo(
    () => ({
      pageNumber,
      perPage,
      filters,
      setFilters,
      sorting,
      handleSorting,
      updateFilterSearchParams,
      setPerPage,
      setPageNumber
    }),
    [pageNumber, perPage, filters, sorting]
  );



  return <ListQueryContext.Provider value={value}>{children}</ListQueryContext.Provider>;
};

export const useListQueryContext = <T extends Record<string, any>>() => {
  const context = useContext(ListQueryContext);
  if (!context) {
    throw new Error('useFormContext must be used within a ListQueryProvider');
  }
  return context as ListQueryContextType<T>;
};

  