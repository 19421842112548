import moment from "moment";

import { useGetProject } from "api/projects";
import TimeChangeReasonModal from "../TimeChangeReasonModal";
import calculateDeliveryDeadlines from "utils/calculate-delivery-deadlines";
import getTimeDifferenceInHours from "utils/get-time-difference-in-hours";

function InlineEditorProvider({
  projectId,
  value,
  handleClose,
  handleSave,
}: {
  projectId: string;
  value: number | Date;
  handleClose: () => void;
  handleSave: (data: any) => Promise<void>; // TODO specify type
}) {
  const [{ data: projectData, loading }] = useGetProject(projectId);

  const getPayload = () => {
    if (loading) {
      return;
    }

    const { timeDifferenceInHours, ianaCode } = getTimeDifferenceInHours(
      projectData.ianaCode
    );

    if (value instanceof Date) {
      const { deliveryDeadline, photographerDeliveryDeadline } =
        calculateDeliveryDeadlines({
          projectData,
          shootingTime: moment(value).format("YYYY.MM.DD HH:mm"),
          timeDifferenceInHours,
        });

      return {
        shootingTimeLocal: moment(value)
          .format("YYYY.MM.DD HH:mm"),
        deliveryDeadlineLocal: moment(deliveryDeadline)
          .tz(ianaCode)
          .format("YYYY.MM.DD HH:mm"),
        photographerDeliveryDeadlineLocal: moment(photographerDeliveryDeadline)
          .tz(ianaCode)
          .format("YYYY.MM.DD HH:mm"),
      };
    }

    if (typeof value === "number") {
      return {
        expectedPhotoNumber: value,
      };
    }
  };

  return (
    <TimeChangeReasonModal
      data={getPayload()}
      onClose={handleClose}
      handleSave={handleSave}
      isOpen
    />
  );
}

export default InlineEditorProvider;
