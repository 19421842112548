import React, {useEffect} from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  Switch,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast
} from '@chakra-ui/react';
import {useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import {useGetClientUserEmails, useUpdateClient} from 'api/clients';
import moment from 'moment';
import config from 'config';


interface Props {
  clientData: any,
}

const ClientManage: React.FC<Props> = ({clientData}) => {
  const {clientId} = useParams();
  const toast = useToast();

  const defaultValues = {
    smsEnabled: clientData.smsEnabled,
    chatEnabled: clientData.chatEnabled,
    allowExtraEmails: clientData.allowExtraEmails,
    allowExport: clientData.allowExport,
    allowWoltSpecificEmails: !!clientData.emails,
    logo: clientData.logo
  };

  const {
    handleSubmit,
    register,
    setValue
  } = useForm({
    defaultValues,
  });

  const [{error: updateClientError, loading}, updateClient] = useUpdateClient(clientId);
  const [{data: clientEmails, error: getClientEmailError}] = useGetClientUserEmails(clientId);

  const handleSaveClick = async (data: any) => {
    try {
      await updateClient({data});
      toast({
        title: 'Company updated',
        status: 'success',
        duration: 3000,
      });
    } catch {
    }
  };

  useEffect(
    () => {
      if (updateClientError) {
        toast(
          {
            title: 'Update is unsuccessful!',
            status: 'error',
            duration: 3000,
          }
        )
      }
      if (getClientEmailError) {
        toast(
          {
            title: 'Get emails failed!',
            status: 'error',
            duration: 3000,
          }
        )
      }
    },
    [updateClientError, getClientEmailError, toast]
  );

  return <Flex justifyContent='center' direction='column' alignItems='center'>
    <Box bg='white' borderRadius='20px' p='20px' h='30%' mb='30px' w='500px'>
      <Text fontWeight='bold' fontSize='20px'>
        Events
      </Text>

            <Table size='sm' m='10px'>
              <Thead textAlign='center'>
                <Tr borderBottom='solid #E2E8F0 2px'>
                  <Th textAlign='left'>Event</Th>
                  <Th textAlign='center'>Date</Th>
                </Tr>
              </Thead>
              <Tbody>
                  <Tr>
                    <Td>Date of registration</Td>
                    <Td textAlign='center'>
                      {moment(clientData.createdAt).format('HH:mm DD.MM.YYYY')}
                    </Td>
                  </Tr>
                 {clientData.adminVerified &&  <Tr>
                    <Td>{`Date of verification (by ${clientData.adminVerified.name})`}</Td>
                    <Td textAlign='center'>
                      {moment(clientData.adminVerified.verifiedAt).format('HH:mm DD.MM.YYYY')}
                    </Td>
                  </Tr>}
              </Tbody>
            </Table>
          </Box>
      <form onSubmit={handleSubmit(handleSaveClick)}>
        <Flex direction ='column' alignItems='center' w={{base: '90vw', md:'500px'}}>
          <Flex justifyContent='start' w='100%'>
              <Flex id='chatEnabled' mb={4}>
                <FormLabel>Chat enabled</FormLabel>
                <Switch defaultChecked={clientData.chatEnabled} ml='10px' {...register('chatEnabled')}></Switch>
              </Flex>
          </Flex>
          <Flex justifyContent='start' w='100%'>
            <Flex id='smsEnabled' mb={4}>
              <FormLabel>SMS enabled</FormLabel>
              <Switch ml='10px' {...register('smsEnabled')} />
            </Flex>
          </Flex>
          <Flex justifyContent='start' w='100%'>
              <Flex id='allowExtraEmails' mb={4}>
                <FormLabel>Enable sending project-related emails to specific email addresses (Foodora AT)</FormLabel>
                <Switch 
                  defaultChecked={clientData.allowExtraEmails} 
                  ml='10px' 
                  {...register('allowExtraEmails')}
                >
                </Switch>
                <Flex direction='column'>
                </Flex>
              </Flex>
          </Flex>
          <Flex justifyContent='start' w='100%'>
              <Flex id='allowExport' mb={4}>
                <FormLabel>Enable project export (Foodora AT)</FormLabel>
                <Switch 
                  defaultChecked={clientData.allowExport} 
                  ml='10px' 
                  {...register('allowExport')}
                >
                </Switch>
                <Flex direction='column'>
                </Flex>
              </Flex>
          </Flex>
          <Flex justifyContent='start' w='100%'>
              <Flex id='chatEnabled' mb={4}>
                <FormLabel>Send Wolt-specific emails to the on-site contact persons</FormLabel>
                <Switch 
                  defaultChecked={defaultValues.allowWoltSpecificEmails} 
                  ml='10px' 
                  onChange ={(e)=>setValue('allowWoltSpecificEmails', e.target.checked)}
                >
                </Switch>
                <Flex direction='column'>
                </Flex>
              </Flex>
          </Flex>
          <Flex direction='column' w={{base: '90vw', md:'500px'}}>
            <FormControl id='logo' mb={4}>
              <FormLabel>Set logo for the company</FormLabel>
              <Input variant='main' {...register('logo')} placeholder='Logo URL'/>
            </FormControl>
            <Flex justifyContent ='center'>
              <Link
                href={`mailto:${config.photonEmailAddress}?bcc=${clientEmails?.join(',')}`}
              >
                <Button variant='photonMedium' w='320px'> 
                  Send an email to all company users
                </Button>
              </Link>
            </Flex>
          </Flex>
      </Flex>
      <Flex justifyContent='center' m='50px'>
        <Button isLoading={loading} type='submit' variant='photonDark' w='200px'>
          Save
        </Button>
      </Flex>
    </form>
  </Flex>;
}

export default ClientManage;
