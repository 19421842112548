
export const deactivateReasons = [
  {label: 'Blacklisted', value: 'blacklist'},
  {label: 'Off', value: 'off'},
  {label: 'Vacation', value: 'vacation'},
  {label: 'Other', value: 'other'},
];

export const services = [
  'event',
  'portrait',
  'property',
  'custom',
  'wedding',
  'familyAndGroup',
  'food',
  'product',
];

export const ApplicantStatus = {
  NEW_APPLICANT: 'new-applicant',
  WAITING_FOR_BRIEFING: 'waiting-for-briefing',
  FINISHING_REGISTRATION: 'finishing-registration',
  SETTING_PASSWORD: 'setting-password',
  WAITING_FOR_FIRST_LOGIN: 'waiting-for-first-login',
  TO_BE_DECIDED: 'to-be-decided',
};

export const AllCreativeStatus = {
  ...ApplicantStatus,
  ACTIVE: 'active',
  DELETED: 'deleted',
  INACTIVE: 'inactive'
}

export const Judgement = {
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  TO_BE_DECIDED: 'to-be-decided'
}

export const ApplicantLabels = {
  [ApplicantStatus.NEW_APPLICANT]: 'On hold',
  [ApplicantStatus.WAITING_FOR_BRIEFING]: 'Waiting for briefing',
  [ApplicantStatus.FINISHING_REGISTRATION]: 'Finishing registration',
  [ApplicantStatus.SETTING_PASSWORD]: 'Setting password',
  [ApplicantStatus.WAITING_FOR_FIRST_LOGIN]: 'Waiting for first login',
  [ApplicantStatus.TO_BE_DECIDED]: 'Maybe',
};

export const CreativeEventType = {
  REGISTRATION: 'Registration',
  ACCEPT: 'Accepted',
  FINISH_REGISTRATION: 'Finished registration',
  FIRST_LOGIN: 'First login',
  ACTIVATE: 'Activate',
  DEACTIVATE: 'Deactivate',
  SET_PASSWORD: 'Password set',
  REMOVED_FROM_PACKAGE: 'Removed from package',
  ADDED_TO_PACKAGE: 'Added to package',
  BRIEF_CALL_BOOKED: 'Brief call booked',
  BOOK_CALL_REMINDER: 'Book call reminder',
  SET_PASSWORD_REMINDER: 'Set password reminder',
  FINISH_REGISTRATION_REMINDER: 'Finish registration reminder',
  RESPONSE_COC: 'Got response of COC',
  AUTO_ACCEPT_COC: 'Auto accept COC',
  ADMIN_COMMENT: 'admin-comment',
  WELCOME_HERE: 'accept to work with us',
};

export const newApplicantStates = Object.entries(ApplicantLabels)
  .map(
    ([value, label]) => ({
      value,
      label,
    }),
  );

export const statusColors: { [key: string]: { primary: string; secondary: string } } = {
  [ApplicantStatus.WAITING_FOR_BRIEFING]: {primary: '#fae38c', secondary: 'black'},
  [ApplicantStatus.FINISHING_REGISTRATION]: {primary: '#B8FA69', secondary: 'black'},
  [ApplicantStatus.WAITING_FOR_FIRST_LOGIN]: {primary: '#a6a3ff', secondary: 'white'},
  [ApplicantStatus.SETTING_PASSWORD]: {primary: '#6DC603', secondary: 'white'},
  [ApplicantStatus.NEW_APPLICANT]: {primary: '#eba834', secondary: 'white'},
  [ApplicantStatus.TO_BE_DECIDED]: {primary: '#D3D3D3', secondary: 'black'},
};

export const CreativeTags: Record<string, string> = {
  INACTIVE: 'inactive'
};
