import {
    Box,
    Button,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useClipboard,
    Flex,
    useToast,
    FormControl,
    FormLabel,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper
  } from '@chakra-ui/react';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { LuCopyCheck, LuCopy } from 'react-icons/lu';
import { EditorStatus, ManageEditorOption } from '../constants';
import { useDeleteEditor, useManageUserStatus, useUpdateEditor } from 'api/editors';
import { useNavigate } from 'react-router-dom';
import { MdModeEdit } from 'react-icons/md';
import { CustomFieldStyle } from './EditorBilling';
import { useForm } from 'react-hook-form';

  export default function EditorManage(props: { editorData: any , refetch: Function}) {
    const { editorData, refetch } = props;
    const [editField, setEditField] = useState('');
    const { onCopy, hasCopied } = useClipboard(editorData.editor.regLink);
    const toast = useToast()
    const navigate = useNavigate()

  const [{ error: manageEditorError, loading: manageEditorLoading }, manageEditor] = useManageUserStatus(
    editorData._id as string,
  );

  const [{ error: deleteEditorError, loading: deleteEditorLoading }, deleteEditor] = useDeleteEditor(
    editorData._id as string,
  );


  const defaultValues = {
    notificationWeighting: editorData.editor.notificationWeighting || 1
  };


  const [
    {
      error: updateEditorError,
      loading
    },
    updateEditor,
  ] = useUpdateEditor(editorData._id as string);


  const {
    formState: {isDirty },
    watch,
    setValue,
    getValues
  } = useForm({
    defaultValues,
  });

  const handleSaveClick = async () => {
    const data = getValues()
    try{
      await updateEditor({data:{...data, notificationWeighting: parseInt(data.notificationWeighting)}});
      toast({
        title: 'Update was successfull',
        status: 'success',
        duration: 3000,
      });
      setEditField('')
    } catch{}
  };

    const copyRegistrationLinkButton = useCallback(() => {
        return !!editorData.editor.regLink
          && (
            <Button ml='5px' onClick={onCopy} h='25px' >
              {hasCopied ? <LuCopyCheck size={20} /> : <LuCopy size={20} />}
            </Button>
          );
      }, [editorData]);

      const handleManage = async (type: string)=>{
        try{
         await manageEditor({data: {type}})
         toast({
            title: 'Editor status updated succesfully',
            status: 'success',
            duration: 3000,
          });
        refetch()
        }catch{}
      }

      const handleDelete = async ()=>{
        try{
            await deleteEditor()
            toast({
                title: 'Editor deleted succesfully',
                status: 'success',
                duration: 3000,
            });
            navigate('/intel/editors', { replace: true });
        }catch{}
      }

      useEffect(() => {
        if (manageEditorError || updateEditorError) {
          toast({
            title: 'Could not update editor',
            status: 'error',
            duration: 3000,
          });
        }
      }, [manageEditorError, updateEditorError]);

      useEffect(() => {
        if (deleteEditorError) {
          toast({
            title: 'Could not delete editor',
            status: 'error',
            duration: 3000,
          });
        }
      }, [deleteEditorError]);

    const watchWeighting = watch('notificationWeighting')


      const manageOptionValue = editorData.status === EditorStatus.INACTIVE ? ManageEditorOption.ACTIVATE : ManageEditorOption.DEACTIVATE
  

    return <Box mt='50px'>
        <Flex direction='column' alignItems='center'>
          <Flex m='10px'>
            <Button 
                onClick={()=>handleManage(manageOptionValue)}
                isLoading= {manageEditorLoading}
                variant= {editorData.status === EditorStatus.INACTIVE ? 'photonLight' : 'photonDarkYellow' }
                m= '0px 10px 10px'
                w='150px'
            >
                {manageOptionValue}
            </Button>
            <Button
                onClick={handleDelete}
                variant='photonDark'
                w='150px'
            >
                Delete
            </Button>
          </Flex>
          <FormControl isInvalid>
              <Flex alignItems='center' direction='column'>
              <FormLabel display='flex' justifyContent={'space-between'}>
                Project notifications related weighting
              </FormLabel>
                {editField === 'notificationWeighting' ? (

                <Flex w='400px'>
                  <NumberInput 
                    onChange={(e)=>setValue('notificationWeighting', e)} 
                    defaultValue={watchWeighting} 
                    min={1} 
                    max={10} 
                    step={1} 
                    bg='white' 
                    w='300px'
                    mb='15px'
                  >
                  <NumberInputField readOnly/>
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                  </NumberInput>
                  <Button onClick={handleSaveClick} variant='photonLight' mt='0px'>
                    Save
                  </Button>
                </Flex>
                ) : (
                  <Flex w='400px'>
                    <Text {...CustomFieldStyle} w='400px' >{watchWeighting}</Text>
                    <Button ml='5px' onClick={() => setEditField('notificationWeighting')}>
                      <MdModeEdit size={30}/>
                    </Button>
                  </Flex>
                )}
              </Flex>
            </FormControl>
          <Box bg='white' borderRadius='20px' p='20px' h='30%' m='20px' w='400px'>
            <Text fontWeight='bold' fontSize='20px'>
              Events
            </Text>

            <Table size='sm' m='10px'>
              <Thead textAlign='center'>
                <Tr borderBottom='solid #E2E8F0 2px'>
                  <Th textAlign='left'>Event</Th>
                  <Th textAlign='center'>Date</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>
                      Invitation
                      {copyRegistrationLinkButton()}
                    </Td>
                  <Td textAlign='center'>
                    {moment(editorData.createdAt).format('HH:mm DD.MM.YYYY')}
                  </Td>
                </Tr>
                {editorData.editor.registeredAt &&
                  <Tr>
                    <Td>Date of registration</Td>
                    <Td textAlign='center'>
                      {moment(editorData.editor.registeredAt).format('HH:mm DD.MM.YYYY')}
                    </Td>
                  </Tr>
                }
              </Tbody>
            </Table>
          </Box>
        </Flex>
    </Box>;
  }

  