import { ClientUserListFilterTypes } from "views/client-user/types";
import { CreativeListFilterTypes } from "views/creative/types";
import { PhotoroomTemplateFilterTypes } from "views/photoroom-templates/PhotoroomTemplateFilters";
import { ProjectListFilterTypes } from "views/projects/types";
import { RecruitmentListFilterTypes } from "views/recruitment/RecruitmentListFilters";

export const clearFilters = (
    filters: 
      | ProjectListFilterTypes
      | CreativeListFilterTypes
      | RecruitmentListFilterTypes
      | PhotoroomTemplateFilterTypes
      | ClientUserListFilterTypes
  ) => {
    const updatedFilters = Object.fromEntries(
      Object.entries(filters).map(([key, value]) => [
        key, 
        value === '' || (Array.isArray(value) && value.length === 0) ? undefined : value
      ])
    );
    return updatedFilters;
  };