import React from "react";
import {
  Box,
  Flex,
  Grid,
  Link,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import moment from 'moment-timezone';

import {
  DATE_CHANGE_REASON,
  ProjectEvent,
} from '../constants';
import { FiExternalLink } from 'react-icons/fi';
import ClipboardIcon from 'components/ClipbordIcon';


export const cancelEvent = {
  cancelByCreative: 'cancelByCreative',
  canceled: 'canceled'
}

type CancellationReason = keyof typeof cancellationReasons;

const cancellationReasons = {
  requestedByContactPerson:
    'Rescheduling needed, due to an unexpected event (power failure, injury, etc.)',
  notTheRightTime: 'Rescheduling needed, they forgot about the photo shoot',
  noNeed: "They don't need the photo shoot",
  other: 'Other',
  changedMyMind: "I've changed my mind",
  busyAtShootingTime: "I'm already busy at this time",
  sickness: 'Because of sickness',
};

interface Props {
  projectData: any;
}

const ProjectEventsAndDocuments: React.FC<Props> = ({projectData}) => {

  const getCancellationType = (cancelledBy: any, type: string) => {
    let cancellationType = '-';

    if (cancelledBy) {
      if (type === cancelEvent.canceled) {
        if (cancelledBy.type === 'enterprise-users') {
          cancellationType = 'Client';
        } else if (cancelledBy.type === 'creatives') {
          cancellationType = 'Photographer in the name of the client';
        } else if (cancelledBy.type === 'backend-users') {
          cancellationType = 'Admin in the name of the client';
        }
      } else if (type === cancelEvent.cancelByCreative) {
        if (cancelledBy.type === 'creatives') {
          cancellationType = 'Photographer';
        } else if (cancelledBy.type === 'backend-users') {
          cancellationType = 'Admin in the name of the photographer';
        }
      }
    }

    return cancellationType;
  };

  return <Grid templateColumns='repeat(auto-fit, minmax(400px, 1fr))' gap={6} justifyItems='center'>
    <Flex justifyContent='start' direction='column' w='90vw' maxW='450px' p='10px' m='10px'>

      <Text align='center' fontWeight='bold'>
        Events
      </Text>
      {projectData?.events.map((e: any, i: number) => {
        {
          if (e.event === ProjectEvent.PRICE_CORRECTION) {
            return (
              <Flex key={i} direction='column' bg='white' borderRadius='20px' m='5px' p='10px'>
                <Text>{`Price correction - ${moment(e.time).format('YYYY.MM.DD HH:mm')}`}</Text>
                <Text>{`type: ${e.details.type}`}</Text>
                <Text>{`amount: ${e.details.amount} ${e.details.currency}`}</Text>
                <Text>{`reason: ${e.details.reason}`}</Text>
                <Text>{`modified by: ${e.details.adminUser?.name ?? '-'}`}</Text>
              </Flex>
            );
          }
          if (e.event === ProjectEvent.ADDITIONAL_PRICE_ITEM) {
            return (
              <Flex key={i} direction='column' bg='white' borderRadius='20px' m='5px' p='10px'>
                <Text>{`Additional price - ${moment(e.time).format('YYYY.MM.DD HH:mm')}`}</Text>
                <Text>{`type: ${e.details.type}`}</Text>
                <Text>{`item: ${e.details.item}`}</Text>
                <Text>{`amount: ${e.details.amount} ${projectData.currency}`}</Text>
                <Text>{`added by: ${e.details.adminUser?.name ?? '-'}`}</Text>
                {e.details.deactivated && <Text color='grey'>(NOT VALID)</Text>}
              </Flex>
            );
          }
          if (e.event === ProjectEvent.PROJECT_DATA_MODIFICATION && e.details.newValues) {
            return <Flex key={i} direction='column' bg='white' borderRadius='20px' m='5px' p='10px'>
              <Text>{`Project data modification - ${moment(e.time).format('LLL')}`}</Text>
              <UnorderedList>
                {Object.entries(e.details.newValues).map((mod, index) => {
                  if (mod[0] === 'extras') {
                    return Object.entries(mod[1]).map((e, subIndex) => (
                      <ListItem key={`${index}-${subIndex}`}>{`${e[0]}: ${e[1]}`}</ListItem>
                    ));
                  }

                  if (
                    ['shootingTime', 'deliveryDeadline', 'photographerDeliveryDeadline'].includes(mod[0])
                    && e.details.oldValues
                  ) {

                    return (
                      <ListItem key={index}>
                        <>
                          <Text>{mod[0]}</Text>
                          <Text>
                            {
                              `${moment(e.details.oldValues[mod[0]])
                                .tz(projectData.ianaCode || 'Europe/Budapest')
                                .format('YYYY.MM.DD HH:mm')}
                                --> 
                                ${moment(mod[1])
                                .tz(projectData.ianaCode || 'Europe/Budapest')
                                .format('YYYY.MM.DD HH:mm')}`
                            }
                          </Text>
                        </>
                      </ListItem>
                    );
                  }

                  return (
                    <ListItem key={index}>
                      {`${mod[0]}: ${mod[1]}`}
                    </ListItem>
                  );
                })
                }
              </UnorderedList>
              {e.details?.dateChangeReason &&
                <Text> {`date change reason: ${DATE_CHANGE_REASON[e.details?.dateChangeReason.toUpperCase()].label}`}</Text>}
              <Text> {`modified by: ${e.details?.adminUser?.name}`}</Text>
            </Flex>;
          }
          if (e.event === ProjectEvent.FEE_REMOVAL) {
            return <Flex key={i} direction='column' bg='white' borderRadius='20px' m='5px' p='10px'>
              <Text>{`Fee removal - ${moment(e.time).format('LLL')}`}</Text>
              <Text>{`type: ${e.details.type}`}</Text>
              <Text> {`modified by: ${e.details?.adminUser?.name}`}</Text>
            </Flex>;
          }
          if (
            e.event === ProjectEvent.APPLY
            || e.event === ProjectEvent.CANCEL_BY_CREATIVE
            || e.event === ProjectEvent.STILL_TAKE
          ) {
            return <Flex key={i} direction='column' bg='white' borderRadius='20px' m='5px' p='10px'>
              <Text>{`${e.event} - ${moment(e.time).format('LLL')}`}</Text>
              <Text> {`photographer: ${e.details?.creativeName}`}</Text>
            </Flex>;
          }
          if (e.event === ProjectEvent.EDITOR_APPLY) {
            return <Flex key={i} direction='column' bg='white' borderRadius='20px' m='5px' p='10px'>
              <Text>{`${e.event} - ${moment(e.time).format('LLL')}`}</Text>
              <Text> {`editor: ${e.details?.editorName}`}</Text>
            </Flex>;
          } else {
            return <Box key={i} borderRadius='20px' bg='white' p='10px' m='5px'>
              <Text key={i}>{` ${e.event} - ${moment(e.time).format(
                'YYYY.MM.DD HH:mm',
              )}`}</Text>
            </Box>;
          }
        }
      })}
    </Flex>

    <Flex direction='column' p='10px' m='10px' w='100%'>
      {
        projectData.photographerReview?.rating
        && <Flex direction='column' maxW='450px' w='100%'>
          <Text align='center' fontWeight='bold'>
            Rating
          </Text>
          <Flex direction='column' bg='white' borderRadius='20px' m='5px' p='10px'>
            <Text fontWeight='bold'>{`Photographer rating:  ${projectData.photographerReview?.rating}`}</Text>
            <Text>{`${projectData.photographerReview?.message}`}</Text>
            <Text color='grey'>{`Photographer: ${projectData.photographerObj?.name}`}</Text>
            {projectData.photographerReview?.userName && (
              <Text color='grey'>{`Rated by: ${projectData.photographerReview?.userName}`}</Text>
            )}
          </Flex>
          {projectData.adminRatingReview?.message && (
            <Flex direction='column' bg='white' borderRadius='20px' m='5px' p='10px'>
              <Text fontWeight='bold'>{`Admin feedback`}</Text>
              <Text>{`${projectData.adminRatingReview?.message}`}</Text>
              <Flex justifyContent='space-between' color='grey'>
                <Text>{moment(projectData.adminRatingReview?.date).format('DD.MM.YYYY')}</Text>
                <Text>{projectData.adminRatingReview?.userName}</Text>
              </Flex>
            </Flex>
          )}
        </Flex>
      }
      {
        projectData.creativeMessage &&
        <Flex direction='column' maxW='450px' w='100%' mt='10px'>
          <Text align='center' fontWeight='bold'>
            Photographer's comment
          </Text>
          <Flex bg='white' borderRadius='20px' m='5px' p='10px' direction='column'>
            <Text>{projectData.creativeMessage}</Text>
            <Text color='grey'> {projectData.photographerObj?.name}</Text>
          </Flex>
        </Flex>
      }
      {
        projectData.events.some((x: any) => Object.values(cancelEvent).includes(x.event))
        && <Flex justifyContent='start' direction='column' w='90vw' maxW='450px' p='10px' mb='10px' lineHeight='30px'>
          <Text align='center' fontWeight='bold'>
            Cancellation
          </Text>
          {
            Object.values(cancelEvent).map(
              (type, i) => {
                const cancelEvents = projectData.events.filter((e: any) => e.event === type);
                return cancelEvents.map(
                  (cancelEvent: any) => <Flex key={i} direction='column' bg='white' borderRadius='20px' m='5px' p='10px'>
                    <Text>{`Type: ${getCancellationType(
                      cancelEvent.details?.cancelledBy,
                      type,
                    )}`}</Text>
                      {
                        cancelEvent.details?.creativeFee > 0
                        && <Text>
                          {`Photographer penalty: ${cancelEvent.details?.creativeFee} ${cancelEvent.details?.creativeFeeCurrency}`}
                        </Text>
                      }
                      {
                        cancelEvent.details?.clientFee > 0
                        && <>
                          <Text>{`Client fee: ${cancelEvent.details?.clientFee} ${projectData.currency}`}</Text>
                          <Text>{`Photographer fee: ${projectData.creativePrice} ${projectData.currency}`}</Text>
                        </>
                      }
                      <Text>
                        {`Reason: ${
                            cancellationReasons[cancelEvent.details?.reason as CancellationReason]
                            || cancelEvent.details?.reason
                            || '-'
                          }`
                        }
                      </Text>
                      {
                        cancelEvent.details?.photoAccessUrl && (
                          <Flex>
                            <Link mt="2px" target="_blank" isExternal href={cancelEvent.details?.photoAccessUrl}> 
                              {cancelEvent.details.photoAccessUrl.length > 50
                                ? `${cancelEvent.details.photoAccessUrl.slice(0, 50)}...`
                                : cancelEvent.details.photoAccessUrl}
                            </Link>
                            <ClipboardIcon value={cancelEvent.details?.photoAccessUrl}/>
                          </Flex>
                        )
                      }
                      <Text>{`Cancelled by: ${cancelEvent.details?.cancelledBy?.name || '-'}`}</Text>
                    </Flex>
                  )
                }
              )
            }
          </Flex>
        }

      {projectData.refFiles.length > 0 && (
        <Flex justifyContent='start' direction='column' w='90vw' maxW='450px'>
          <Text align='center' fontWeight='bold'>
            Documents
          </Text>
          {projectData.refFiles.map((file: { name: string; url: string }, i: number) => (
            <Link isExternal target='_blank' href={file.url} textDecoration='none'>
              <Text key={i} m='10px' bg='white' borderRadius='20px' p='10px'>
                {file.name}
              </Text>
            </Link>
          ))}
        </Flex>
      )}
    </Flex>
  </Grid>;
}

export default ProjectEventsAndDocuments;
