import React, {KeyboardEvent, useRef, useState} from 'react';

import {
  Button,
  Checkbox,
  Flex,
  Grid,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Switch,
  Text,
} from '@chakra-ui/react';
import {AsyncSelect, Select as ChakraReactSelect} from 'chakra-react-select';
import {TiDeleteOutline} from 'react-icons/ti';
import {CreativeListFilterTypes} from '../types';
import {getPackages} from 'api/packages';
import {citySelectorStyles, useLoadCityOptionsWithDebounce} from '../helpers/getCities';
import Card from 'components/card/Card';
import {useGetCreativeCountries, useGetCreativesCSV} from 'api/staff-api';
import {downloadFile} from 'utils/download-file';
import moment from 'moment';
import {ApplicantLabels, deactivateReasons, newApplicantStates, services,} from '../constants';
import {chakraStyles, Option} from '../variables/styles';
import useCountryList from 'hooks/useCountryList';
import {sortCountries} from 'utils/sort-countries';


const RemoveInputContent = ({
                              handleFilterChange,
                              name,
                            }: {
  handleFilterChange: Function;
  name: string;
}) => {
  return (
    <InputRightElement>
      <Button size='20' onClick={() => handleFilterChange('', name)}>
        <TiDeleteOutline size='20'/>
      </Button>
    </InputRightElement>
  );
};

const handleResetFilters = async (
  setFilters: React.Dispatch<React.SetStateAction<CreativeListFilterTypes>>,
  handleSearch: (resetFilters: CreativeListFilterTypes) => Promise<void>,
  filters: CreativeListFilterTypes,
) => {
  const updatedFilters: any = {};
  for (const key in filters) {
    if (key === 'workingArea' || key === 'status') {
      updatedFilters[key] = [];
    } else {
      updatedFilters[key] = '';
    }
  }
  setFilters(updatedFilters);
  handleSearch(updatedFilters);
};

const CreativeListFilters = ({
                               filters,
                               handleFilterChange,
                               handleSearch,
                               setFilters,
                               type,
                             }: {
  filters: CreativeListFilterTypes;
  handleFilterChange: Function;
  handleSearch: (resetFilters: CreativeListFilterTypes) => Promise<void>;
  setFilters: React.Dispatch<React.SetStateAction<CreativeListFilterTypes>>;
  type: string;
}) => {
  const [showFilters, setShowFilters] = useState(true);
  const loadCityOptionsWithDebounce = useLoadCityOptionsWithDebounce();
  const {getCountryName} = useCountryList();
  const [{data: creativeCountries}] = useGetCreativeCountries();
  const [{loading: exportLoading}, exportCreatives] = useGetCreativesCSV();
  const [showInactivePackages, setShowInactivePackages] = useState(false)
  const sortedCountries = sortCountries(creativeCountries)
  const timer = useRef<ReturnType<typeof setTimeout> | undefined>();

  const loadOptionsWithDebounce = (searchText: string, setOptions: Function) => {
    clearTimeout(timer.current);
    timer.current = setTimeout(
      () =>
        getPackages(searchText).then((options) =>
          setOptions(
            options
              ?.filter(
                (p: { name: string; client: { name: string; status: string }; status: string }) =>
                  showInactivePackages || p.status === 'enabled', // Filter out inactive packages if showInactivePackage is false
              )
              .map((p: { name: string; client: { name: string; status: string }; status: string }) => ({
                value: `${p.name}, (${p.client?.name})`,
                label: `${p.name}${p.status !== 'enabled' ? ' / (NA)' : ''}, (${p.client?.name})`,
              })) ?? [],
          ),
        ),
      500,
    );
  };

  const handleExportCreatives = async () => {
    let result;
    try {
      result = await exportCreatives({
        params: {
          filters,
          type,
        },
        responseType: 'arraybuffer',
      });
    } catch (e) {
      console.log(e);
    } finally {
      if (result && result.data) {
        downloadFile(
          result.data,
          `creative_list_${type}_${moment().format('DD_MMMM_hh_mm')}.csv`,
          'application/octet-stream',
        );
      }
    }
  };

  const handleWorkingAreaFilterChange = (array: { value: string; label: string }[]) => {
    const updatedCityFilter = array.map((s) => s.value);
    handleFilterChange(updatedCityFilter, 'workingArea');
  };

  const handleEnterPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch(filters);
    }
  };

  const handleArrayFilterChange = (array: { value: string; label: string }[], name: string) => {
    const updatedArrayFilter = array.map((s) => s.value);
    handleFilterChange(updatedArrayFilter, name);
  };

  return <Card flexDirection='column' p='0px 20px'>
    {
      showFilters
      && <Flex width='150px' alignItems='center' m='18px 15px'>
        <Text color='gray.400' mr='20px'>
          Show filters
        </Text>
        <Switch
          isChecked={showFilters}
          colorScheme='brandScheme'
          size='md'
          onChange={() => setShowFilters((prevShowFilters) => !prevShowFilters)}
        />
      </Flex>
    }

    {
      showFilters
      && <Grid
        templateColumns='repeat(auto-fit, minmax(300px, 1fr))'
        gap='10px 10px'
        m='0px 60px 0px 20px'
        justifyItems='center'
        alignItems='center'
      >
        {
          filters.search !== undefined
          && <Flex direction='column' mb={4}>
            <Text>Name/ email:</Text>
            <InputGroup w='300px'>
              {filters.search && (
                <RemoveInputContent handleFilterChange={handleFilterChange} name={'search'}/>
              )}
              <Input
                variant='tableSearch'
                placeholder='Search by name or email'
                onChange={(e) => handleFilterChange(e.target.value, 'search')}
                value={filters.search}
                onKeyDown={handleEnterPress}
                onBlur={() => handleSearch(filters)}
              />
            </InputGroup>
          </Flex>
        }
        {
          filters.country !== undefined
          && <Flex direction='column' mb={4} width='300px'>
            <Text>Country:</Text>
            <Select
              onChange={(e) => handleFilterChange(e.target.value, 'country')}
              placeholder='Select country'
              value={filters.country}
            >
              {sortedCountries?.map((country: string) => (
                <option key={country} value={country}>
                  {getCountryName(country as unknown as string)}
                </option>
              ))}
            </Select>
          </Flex>
        }
        {
          filters.homeTown !== undefined
          && <Flex direction='column' mb={4}>
            <Text>Hometown:</Text>
            <InputGroup zIndex={2}>
              <AsyncSelect
                placeholder='Select hometown'
                loadOptions={loadCityOptionsWithDebounce}
                onChange={(e: any) => handleFilterChange(e.value, 'homeTown')}
                chakraStyles={citySelectorStyles}
                getOptionValue={(option: any) => option.value}
                getOptionLabel={(option: any) => option.label}
                isClearable={!!filters.homeTown}
                value={
                  filters.homeTown
                    ? {value: filters.homeTown, label: filters.homeTown}
                    : {value: '', label: 'Select hometown'}
                }
                components={{
                  DropdownIndicator: () => null,
                  ClearIndicator: () => (
                    <RemoveInputContent
                      handleFilterChange={() => handleFilterChange([], 'homeTown')}
                      name={'homeTown'}
                    />
                  ),
                }}
              />
            </InputGroup>
          </Flex>
        }
        {
          filters.workingArea !== undefined
          && <Flex direction='column' mb={4}>
            <Text>Cities without travel costs:</Text>
            <InputGroup zIndex={2}>
              <AsyncSelect
                isMulti
                placeholder='Select working area'
                loadOptions={loadCityOptionsWithDebounce}
                onChange={(e: any) => handleWorkingAreaFilterChange(e)}
                chakraStyles={citySelectorStyles}
                getOptionValue={(option: any) => option.value}
                getOptionLabel={(option: any) => option.label}
                isClearable={!!filters.workingArea}
                value={
                  filters.workingArea &&
                  filters.workingArea.map((s: string) => ({value: s, label: s}))
                }
                components={{
                  DropdownIndicator: () => null,
                  ClearIndicator: () => (
                    <RemoveInputContent
                      handleFilterChange={() => handleFilterChange([], 'workingArea')}
                      name={'workingArea'}
                    />
                  ),
                }}
              />
            </InputGroup>
          </Flex>
        }
        {
          filters.service !== undefined
          && <Flex direction='column' mb={4} width='300px' zIndex={0}>
            <Text>Services:</Text>
            <Select
              onChange={(e) => handleFilterChange(e.target.value, 'service')}
              placeholder='Select service'
              value={filters.service}
            >
              {services.map((service: string) => (
                <option key={service} value={service}>
                  {service}
                </option>
              ))}
            </Select>
          </Flex>
        }
        {
          filters.status !== undefined
          && <Flex direction='column' mb={4} width='300px'>
            <Text>Status:</Text>
            <ChakraReactSelect
              isMulti
              options={newApplicantStates}
              components={{Option}}
              placeholder='Select statuses'
              onChange={(e: any) => handleArrayFilterChange(e, 'status')}
              closeMenuOnSelect={false}
              value={filters.status?.map((s: string) => ({value: s, label: ApplicantLabels[s]}))}
              chakraStyles={chakraStyles}
            />
          </Flex>
        }
        {
          filters.activity !== undefined
          && <Flex direction='column' mb={4} width='300px'>
            <Text>Status:</Text>
            <Select
              onChange={(e) => handleFilterChange(e.target.value, 'activity')}
              placeholder='Select status'
              value={filters.activity}
            >
              <option key={'active'} value={'active'}>
                Active
              </option>
              <option key={'inactive'} value={'inactive'}>
                Inactive
              </option>
            </Select>
          </Flex>
        }
        {
          filters.packageName !== undefined
          && <Flex direction='column' mb={4}>
            <Flex justifyContent='space-between'>
              <Text>Package name:</Text>
              <Checkbox
                size={'sm'}
                isChecked={showInactivePackages}
                onChange={(e) => {
                  setShowInactivePackages(e.target.checked);
                }}
              >
                <Text fontSize='13px'>
                  Show inactive packages
                </Text>
              </Checkbox>
            </Flex>

            <InputGroup zIndex={1}>
              <AsyncSelect
                isDisabled={filters.notOnPackage}
                placeholder='Select package'
                loadOptions={loadOptionsWithDebounce}
                onChange={(e) => handleFilterChange(e.value, 'packageName')}
                chakraStyles={citySelectorStyles}
                isClearable={!!filters.packageName}
                components={{
                  DropdownIndicator: () => null,
                  ClearIndicator: () => (
                    <RemoveInputContent
                      handleFilterChange={handleFilterChange}
                      name={'packageName'}
                    />
                  ),
                }}
                value={
                  filters.packageName
                    ? {label: filters.packageName, value: filters.packageName}
                    : {label: 'Select package name', value: ''}
                }
              />
            </InputGroup>
          </Flex>
        }


        {
          filters.notOnPackage !== undefined
          && <Flex ml='10px' mt='5px' direction='column' w='100%' pl='5px'>
            <Checkbox
              size={'lg'}
              isChecked={filters.notOnPackage}
              onChange={(e) => {
                handleFilterChange(e.target.checked, 'notOnPackage');
              }}
            >
              <Text fontSize='16px'>
                List only the photographers who are not assigned to any packages
              </Text>
            </Checkbox>
          </Flex>
        }
        {
          filters.adminFlag !== undefined
          && <Flex ml='10px' mt='5px' direction='column' w='100%' pl='5px'>
            <Checkbox
              size={'lg'}
              isChecked={filters.adminFlag}
              onChange={(e) => {
                handleFilterChange(e.target.checked, 'adminFlag');
              }}
            >
              <Text fontSize='16px'>
                Show only flagged photographers
              </Text>
            </Checkbox>
          </Flex>
        }

        {
          filters.deactivateReason !== undefined
          && <Flex direction='column' mb={4} width='300px'>
            <Text>Cause of deactivation:</Text>
            <Select
              placeholder='Select reason'
              value={filters.deactivateReason}
              onChange={(e) => handleFilterChange(e.target.value, 'deactivateReason')}
            >
              {
                deactivateReasons.map(
                  (reason: { value: string; label: string }) => <option value={reason.value} key={reason.value}>
                    {reason.label}
                  </option>
                )
              }
            </Select>
          </Flex>
        }
      </Grid>
    }

    <Flex
      justifyContent={{base: 'space-around', lg: 'space-between'}}
      alignItems='center'
      direction={{base: 'column', lg: 'row'}}
    >
      {
        showFilters
        && <Button
          onClick={() => handleResetFilters(setFilters, handleSearch, filters)}
          variant='photonMedium'
          ml={{base: '10px', lg: '30px'}}
          w='130px'
        >
          Reset filters
        </Button>
      }
      {
        !showFilters
        && <Flex width='150px' justifyContent='center' alignItems='center' ml='10px'>
          <Text color='gray.400' mr='20px'>
            Show filters
          </Text>
          <Switch
            isChecked={showFilters}
            colorScheme='brandScheme'
            size='md'
            onChange={() => setShowFilters((prevShowFilters) => !prevShowFilters)}
          />
        </Flex>
      }

      <Button
        onClick={() => handleExportCreatives()}
        variant='photonLight'
        ml='20px'
        w='130px'
        alignSelf={{base: 'left', md: 'right'}}
        mt={{base: '10px'}}
        isLoading={exportLoading}
      >
        Export
      </Button>
    </Flex>
  </Card>;
};

export default CreativeListFilters;
