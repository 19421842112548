import {
  AllCreativeStatus,
  ApplicantStatus,
  CreativeEventType,
  Judgement,
  newApplicantStates,
} from '../constants';

import {useNavigate, useParams} from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  IconButton,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useClipboard,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  useActivateCreative,
  useDeleteCreative,
  useGetCreativePackages,
  useManageCreative,
  useMoveToWaitingForBriefing,
} from '../../../api/staff-api';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {CgUserRemove} from 'react-icons/cg';
import {useRemoveCreativeFromPackage} from '../../../api/packages';
import {IoAddCircleOutline} from 'react-icons/io5';
import AddCreativeToPackageModal from './AddCreativeToPackageModal';
import HiringModal from './HiringModal';
import {CreativeEvent} from '../types';
import DeactivateModal from './DeactivateModal';
import {LuCopy, LuCopyCheck} from 'react-icons/lu';
import {CreativeEvents} from "./manage/CreativeEvents";


export default function CreativeManage(props: { creativeData: any; callGetCreative: Function }) {
  const {creativeData, callGetCreative} = props;

  const {onCopy: onCopyRegistrationLink, hasCopied: hasCopiedRegistrationLink} = useClipboard(creativeData.creativeRegistrationLink);
  const {onCopy: onCopyConfirmationLink, hasCopied: hasCopiedConfirmationLink} = useClipboard(creativeData.creativeConfirmationLink);

  const [isPackageModalOpen, setIsPackageModalOpen] = useState(false);
  const [judgement, setJudgment] = useState('');
  const [actions] = useState<Map<string, Function>>(new Map());

  const {creativeId} = useParams();
  const toast = useToast();

  const {isOpen, onOpen, onClose} = useDisclosure();
  const {
    isOpen: isHiringModalOpen,
    onOpen: openHiringModal,
    onClose: closeHiringModal,
  } = useDisclosure();

  const {
    isOpen: isDeactivateModalOPen,
    onOpen: openDeactivateMoadal,
    onClose: closeDeactivateModal,
  } = useDisclosure();

  const navigate = useNavigate();

  const [{error: activateCreativeError}, activateCreative] = useActivateCreative(
    creativeId as string,
  );

  const [{data: creativePackages, error: getCreativePackagesError}, getCreativePackages] =
    useGetCreativePackages(creativeId as string);

  const [{error: removeCreativeFromPackageError}, removeCreativeFromPackage] =
    useRemoveCreativeFromPackage();

  const [
    {
      loading: moveToWaitingForBriefingLoading,
      error: moveToWaitingForBriefingError,
    },
    moveToWaitingForBriefing,
  ] = useMoveToWaitingForBriefing(creativeId as string);

  const [{error: deleteCreativeError}, deleteCreative] = useDeleteCreative(creativeId as string);

  const [{ error: manageCreativeError, loading: manageCreativeLoading }, manageCreative] =
    useManageCreative();

  useEffect(() => {
    if (getCreativePackagesError) {
      toast({
        title: 'Getting creative related packages error',
        status: 'error',
        duration: 3000,
      });
    }
  }, [getCreativePackagesError]);

  useEffect(() => {
    if (activateCreativeError) {
      toast({
        title: 'Activate creative error',
        status: 'error',
        duration: 3000,
      });
    }
  }, [activateCreativeError]);

  useEffect(() => {
    if (deleteCreativeError) {
      toast({
        title: 'Delete creative error',
        status: 'error',
        duration: 3000,
      });
    }
  }, [deleteCreativeError]);

  useEffect(() => {
    if (manageCreativeError) {
      toast({
        title: 'Managing the hiring process update error',
        status: 'error',
        duration: 3000,
      });
    }
  }, [manageCreativeError]);

  useEffect(() => {
    if (removeCreativeFromPackageError) {
      toast({
        title: 'Unable to remove photographer from package',
        status: 'error',
        duration: 3000,
      });
    }
  }, [removeCreativeFromPackageError]);

  useEffect(() => {
    if (moveToWaitingForBriefingError) {
      toast({
        title: 'Unable to set set creative status to waiting-for-briefing',
        status: 'error',
        duration: 3000,
      });
    }
  }, [moveToWaitingForBriefingError]);

  useEffect(
    () => {
      actions.set(
        'getRegistrationLink',
        () => !!creativeData.creativeRegistrationLink
          && (
            <Button ml='5px' onClick={onCopyRegistrationLink} h='25px'>
              {hasCopiedRegistrationLink ? <LuCopyCheck size={20}/> : <LuCopy size={20}/>}
            </Button>
          )
      );

      actions.set(
        'getConfirmationLink',
        () => !!creativeData.creativeConfirmationLink && !creativeData.creativeConfirmationLink.endsWith("null")
          && (
            <Button ml='5px' onClick={onCopyConfirmationLink} h='25px'>
              {hasCopiedConfirmationLink ? <LuCopyCheck size={20}/> : <LuCopy size={20}/>}
            </Button>
          )
      );

      creativeData.events?.every((e: CreativeEvent) => e.event !== CreativeEventType.REGISTRATION)
        && creativeData.events?.splice(
          0,
          0,
          {
            event: CreativeEventType.REGISTRATION,
            time: creativeData.createdAt,
          }
        );
    },
    [creativeData]
  );

  const handleActivateCreative = async () => {
    try {
      await activateCreative();
      toast({
        title: 'Creative activated successfully',
        status: 'success',
        duration: 3000,
      });
      await callGetCreative();
    } catch (e) {
      // do nothing
    }
  };

  const handleDeleteCreative = async () => {
    try {
      await deleteCreative();
      toast({
        title: 'Creative deleted successfully',
        status: 'success',
        duration: 3000,
      });
      navigate('/intel/creatives/active', {replace: true});
    } catch (e) {
      // do nothing
    }
  };

  const handleManageCreative = async (judgement: string) => {
    try {
      const res = await manageCreative({
        data: {
          judgement,
        },
        params: {
          creativeId,
        },
      });
      toast({
        title: res.data.message,
        status: 'success',
        duration: 3000,
      });
      await callGetCreative();
    } catch {
      // do nothing
    }
  };

  const handleRemoveCreativeFromPackage = async (packageId: string) => {
    try {
      await removeCreativeFromPackage({
        data: {
          packageId,
          creativeId,
        },
      });
      toast({
        title: 'Photographer removed from package',
        status: 'success',
        duration: 3000,
      });
      await getCreativePackages();
      await callGetCreative();
    } catch (e) {
      // do nothing
    }
  };

  const handleMoveToWaitingForBriefing = async () => {
    try {
      await moveToWaitingForBriefing();
      toast({
        title: 'Status changed to waiting for briefing',
        status: 'success',
        duration: 3000,
      });
      await callGetCreative();
    } catch (e) {
    }
  };

  return (
    <Flex direction='column' justifyContent='center' alignItems='center'>
      {!creativeData.events?.some((e: CreativeEvent) => e.event === CreativeEventType.ACCEPT) &&
        creativeData.status !== AllCreativeStatus.DELETED &&
        creativeData.status !== AllCreativeStatus.DELETED && (
          <Box
            bg='white'
            borderRadius='20px'
            p='20px'
            m='20px'
            width={{base: 'auto', md: '900px'}}
          >
            <Flex justifyContent='space-between'>
              <Text fontWeight='bold' fontSize='20px'>
                Managing the hiring process
              </Text>
              <Text>
                {newApplicantStates.find((state: any) => state.value === creativeData.status)
                  ?.label || creativeData.status}
              </Text>
            </Flex>
            <Flex
              justifyContent='space-around'
              alignItems='center'
              m='10px'
              direction={{base: 'column', md: 'row'}}
            >
              <Button
                variant='photonDark'
                w='250px'
                onClick={() => {
                  setJudgment(Judgement.REJECTED);
                  openHiringModal();
                }}
                mb={{base: '10px', md: '0px'}}
                isDisabled={manageCreativeLoading}
              >
                Reject
              </Button>
              {creativeData.status !== ApplicantStatus.TO_BE_DECIDED &&
                <Button
                  isDisabled={manageCreativeLoading}
                  variant='photonDarkYellow'
                  w='250px'
                  onClick={() => handleManageCreative(Judgement.TO_BE_DECIDED)}
                  mb={{base: '10px', md: '0px'}}
                >
                  Maybe
                </Button>
              }
              {creativeData.status === ApplicantStatus.NEW_APPLICANT ? (
                <Button
                  variant='photonLight'
                  w='250px'
                  m='10px'
                  onClick={handleMoveToWaitingForBriefing}
                  isLoading={moveToWaitingForBriefingLoading}
                >
                  Move to waiting for briefing
                </Button>
              ) : (
                <Button
                  isDisabled={manageCreativeLoading}
                  m='0px'
                  variant='photonLight'
                  w='250px'
                  onClick={() => {
                    setJudgment(Judgement.ACCEPTED);
                    openHiringModal();
                  }}
                >
                  Accept
                </Button>
              )}
            </Flex>
          </Box>
        )}
      <Flex justifyContent='space-around' direction='column' w='100%'>
        <Flex
          justifyContent='center'
          alignItems={'center'}
          direction={{base: 'column', md: 'row'}}
        >
          <CreativeEvents
            events={
              creativeData?.events
                ?.filter(
                  (e: CreativeEvent) => ![CreativeEventType.ADMIN_COMMENT, CreativeEventType.RESPONSE_COC, CreativeEventType.AUTO_ACCEPT_COC].includes(e.event)
                ).sort(
                  (a: CreativeEvent, b: CreativeEvent) => moment(a.time).diff(moment(b.time))
                )
            }
            title="Events"
            actions={actions}
          />

          <Flex
            direction='column'
            bg='white'
            borderRadius='20px'
            p='20px'
            justifyContent='space-around'
            h='200px'
            w='200px'
            m='20px'
          >
            <Text fontWeight='bold' fontSize='20px'>
              Actions
            </Text>

            {creativeData.status === AllCreativeStatus.INACTIVE ? (
              <Button variant='photonDark' w='150px' onClick={handleActivateCreative} m='10px'>
                Activate
              </Button>
            ) : (
              <Button
                variant='photonDark'
                w='150px'
                m='10px'
                onClick={openDeactivateMoadal}
                isDisabled={[AllCreativeStatus.INACTIVE, AllCreativeStatus.DELETED].includes(creativeData.status)}
              >
                Deactivate
              </Button>
            )}
            <Button
              variant='photonLight'
              w='150px'
              m='10px'
              onClick={onOpen}
              isDisabled={creativeData.status === AllCreativeStatus.DELETED}
            >
              Delete user
            </Button>
          </Flex>
        </Flex>

        <Box bg='white' borderRadius='20px' p='20px' m='20px'>
          <Flex justifyContent='space-between'>
            <Text fontWeight='bold' fontSize='20px'>
              Packages
            </Text>
            <IconButton
              bg='white'
              onClick={() => setIsPackageModalOpen(true)}
              colorScheme='photonOffWhite'
              color='photonDeepBlue'
              aria-label='Delete user'
              icon={<IoAddCircleOutline size={30}/>}
            />
          </Flex>

          {
            creativePackages?.length > 0
            && <Table size='sm' m='10px'>
              <Thead>
                <Tr borderBottom='solid #E2E8F0 2px'>
                  <Th>Package name</Th>
                  <Th>Client name</Th>
                  <Th w='150px' textAlign='center'>
                    Remove creative from package
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {creativePackages?.map(
                  (pack: { name: string; clientName: string; id: string }, i: string) => (
                    <Tr key={i}>
                      <Td>{pack.name}</Td>
                      <Td>{pack.clientName}</Td>
                      <Td textAlign='center'>
                        <Button
                          onClick={() => handleRemoveCreativeFromPackage(pack.id)}
                          bg='transparent'
                          _hover={{bg: 'transparent'}}
                        >
                          <Box _hover={{transform: 'scale(1.3)'}}>
                            <CgUserRemove size={20}/>
                          </Box>
                        </Button>
                      </Td>
                    </Tr>
                  ),
                )}
              </Tbody>
            </Table>
          }
          {
            !creativePackages?.length
            && <Text mt='50px' textAlign='center'>
              This creative is not attached to any package
            </Text>
          }
        </Box>
      </Flex>
      {
        <CreativeEvents
          events={
            creativeData?.events?.filter(
              (e: CreativeEvent) => [CreativeEventType.RESPONSE_COC, CreativeEventType.AUTO_ACCEPT_COC].includes(e.event)
            )
          }
          title="COC Events"
          actions={actions}
        />
      }
      <Modal isOpen={isOpen} onClose={onClose} size='lg' isCentered={true}>
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader textAlign='center'>
            Are you sure you want to delete this photographer?
          </ModalHeader>
          <ModalCloseButton/>
          <ModalFooter display='flex' justifyContent='center'>
            <Button variant='photonMedium' mr={3} onClick={onClose} width='120px'>
              No
            </Button>
            <Button variant='photonDark' mr={3} onClick={handleDeleteCreative} width='120px'>
              Proceed
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AddCreativeToPackageModal
        creativeData={creativeData}
        isOpen={isPackageModalOpen}
        onClose={async (success?: boolean) => {
          setIsPackageModalOpen(false);
          if (success) {
            await getCreativePackages();
            await callGetCreative();
          }
        }}
      />
      <HiringModal
        creativeId={creativeData._id}
        judgement={judgement}
        isOpen={isHiringModalOpen}
        onClose={closeHiringModal}
        refetch={callGetCreative}
      />

      <DeactivateModal
        creativeId={creativeData._id}
        isOpen={isDeactivateModalOPen}
        onClose={closeDeactivateModal}
        refetch={callGetCreative}
        getCreativePackages={getCreativePackages}
      />
    </Flex>
  );
}
